import moment from 'moment';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import { getCurrencySymbol } from '../../containers/MapPage/Map';
import { getPriceType } from '../../containers/MapPage/MapPage';
import { getFilePath } from '../../helpers/useHelper';
import { Alert, Announce } from '../../models/Announces';
import ImageItem from '../../assets/img/propertylist.png';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconRingBell from '../../icons/IconRingBell';
import './AlertAddModal.scss';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  items?: Alert[];
}

const AlertAddModal: React.FC<Props> = ({ isModalOpen, items, closeModal }) => {
  const onCloseModal = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={onCloseModal}
        animation={true}
        className={'modal alert-modal'}
      >
        <Modal.Header>
          <div className='close-btn-modal' onClick={onCloseModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          <span>
            <IconRingBell />{' '}
            {items?.reduce((sum, item) => sum + (item.announcements || 0), 0)}{' '}
            anunturi noi in baza alertelor
          </span>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey='0' className='accordion-add'>
            {items?.map(
              (item: Alert, index: number) =>
                item.announcements > 0 && (
                  <Accordion.Item eventKey={`${index}`}>
                    <Accordion.Header>
                      <span className='header-left'>
                        <span className='number'>
                          <strong>{item.id}</strong>
                        </span>
                        <span className='title'>{item.title} </span>
                      </span>
                      <span className='result'>
                        {item.announcements} anunturi
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {item.rows.map((row: Announce) => (
                        <div className='accordion-list-item'>
                          <div className='column-list-item'>
                            <div className='info-img'>
                              <img
                                src={
                                  row.media[0].media.path
                                    ? getFilePath(row.media[0].media.path)
                                    : ImageItem
                                }
                                alt='img-item'
                              />{' '}
                            </div>
                            <div className='title'>
                              <strong>
                                {getPriceType(row)} {row?.categoryTitle} zona{' '}
                                {row?.zoneLabel}
                              </strong>
                            </div>
                          </div>
                          <div className='column-list-item'>
                            <div className='date'>
                              {row.dateUpdated || row.dateCreated
                                ? moment(
                                    row.dateUpdated ?? row.dateCreated,
                                  ).format('DD.MM.YYYY HH:mm')
                                : ''}
                            </div>
                          </div>
                          <div className='column-list-item'>
                            <div className='price'>
                              <strong>
                                {row?.currencySymbol?.length < 2
                                  ? row?.currencySymbol
                                  : getCurrencySymbol(row?.currencySymbol)}{' '}
                                {Number(row.price) % 1 === 0
                                  ? Number(row.price)
                                  : Number(row.price).toFixed(1)}
                              </strong>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className='button-block'>
                        <a
                          href={'/ads/' + item.id}
                          className='button-blue button-sm'
                        >
                          Vezi toate
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ),
            )}
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AlertAddModal;
