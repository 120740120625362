interface Props {}

const IconClose: React.FC<Props> = props => {
  return (
    <svg
      {...props}
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.26762 7.50016L14.6338 2.13402C15.1225 1.64528 15.1225 0.855295 14.6338 0.366555C14.145 -0.122185 13.355 -0.122185 12.8663 0.366555L7.50016 5.73269L2.13402 0.366555C1.64528 -0.122185 0.855295 -0.122185 0.366555 0.366555C-0.122185 0.855295 -0.122185 1.64528 0.366555 2.13402L5.73269 7.50016L0.366555 12.8663C-0.122185 13.355 -0.122185 14.145 0.366555 14.6338C0.6103 14.8775 0.930293 15 1.25029 15C1.57028 15 1.89027 14.8775 2.13402 14.6338L7.50016 9.26762L12.8663 14.6338C13.11 14.8775 13.43 15 13.75 15C14.07 15 14.39 14.8775 14.6338 14.6338C15.1225 14.145 15.1225 13.355 14.6338 12.8663L9.26762 7.50016Z'
        fill='black'
      />
    </svg>
  );
};

export default IconClose;
