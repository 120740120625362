import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FilterListNewEvaluation from '../../components/FilterListNewEvaluation';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import PriceEvaluationGrafic from '../../components/PriceEvaluationGrafic';
import SideNav from '../../components/SideNav';
import { clearEvaluation } from '../../redux/evaluations/evaluations-slice';
import {
  createEvaluation,
  getEvaluation,
  updateEvaluation,
} from '../../redux/evaluations/evaluationsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useHelper, { haveUserPermission } from '../../helpers/useHelper';
import { EvaluationBody } from '../../models/Evaluation';
import { Permissions } from '../../models/Permissions';
import './NewEvaluation.scss';

interface Props {}

const NewEvaluation: React.FC<Props> = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();
  const { evaluation } = useAppSelector(state => state.evaluations);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [formFilters, setFormFilters] = useState<any>(undefined);
  const [formGraphic, setFormGraphic] = useState<any>(undefined);
  const [evaluationBody, setEvaluationBody] = useState<EvaluationBody>({});
  const [loading, setLoading] = useState(id ? true : false);
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.AddEvaluation),
  );

  useEffect(() => {
    if (!!evaluation && categoryId) {
      setEvaluationBody({
        categoryId: categoryId,
        medianPrice: evaluation.medianPrice,
        processedAnnounces: evaluation.processedAnnounces,
        recommendedPriceFrom:
          evaluation.recommendedPrice?.from ?? evaluation.recommendedPriceFrom,
        recommendedPriceTo:
          evaluation.recommendedPrice?.to ?? evaluation.recommendedPriceTo,
        sqmPrice: evaluation.sqmPrice,
        sqmRecommendedPrice: evaluation.sqmRecommendedPrice,
        sqmSubEvaluationPrice: evaluation.sqmSubEvaluationPrice,
        filters: evaluation.filters,
      });
      !id && setFormGraphic(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation, categoryId]);

  useEffect(() => {
    setFormGraphic(null);
    if (id) {
      dispatch(getEvaluation(id));
    } else {
      dispatch(clearEvaluation());
      setFormGraphic(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const saveEvaluation = async () => {
    try {
      const response = id
        ? await dispatch(
            updateEvaluation({ id: id, evaluation: evaluationBody }),
          )
        : await dispatch(createEvaluation(evaluationBody));
      unwrapResult(response);

      if (!response.payload.error) {
        toast.success('Evaluarea a fost salvata cu success!');
        navigate('/price-evaluation');
      }
    } catch (err: any) {
      diplayErrorMessages(err);
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper new-evaluation-wrapper'>
        <Header
          title='Evaluare pret'
          subtitle=''
          isbutton={false}
          btnText=''
          breadcrumbs={[
            { link: '/', name: 'Home' },
            { link: '/price-evaluation', name: 'Evaluare pret' },
            { link: '', name: id ? 'Editare evaluare' : 'Evaluare noua' },
          ]}
        />
        {havePermission ? (
          <div className='bg-white new-evaluation-container'>
            <FilterListNewEvaluation
              formFilters={formFilters}
              setFormFilters={setFormFilters}
              setCategoryId={setCategoryId}
              setFormGraphic={setFormGraphic}
              loading={loading}
              setLoading={setLoading}
            />

            {!loading &&
              ((formGraphic && id) || !id) &&
              !!evaluation &&
              evaluation.processedAnnounces > 0 && (
                <>
                  <PriceEvaluationGrafic
                    evaluation={evaluation}
                    evaluationBody={evaluationBody}
                    formFilters={id ? formGraphic : formFilters}
                  />
                  <div className='buttons-bottom display-flex'>
                    <button
                      className='button button-border'
                      onClick={() => navigate(-1)}
                    >
                      Inchide
                    </button>
                    <button
                      className='button button-blue'
                      onClick={saveEvaluation}
                    >
                      Salveaza
                    </button>
                  </div>
                </>
              )}
            {!!evaluation && evaluation.processedAnnounces === 0 && (
              <div>Au fost analizate 0 anunturi</div>
            )}
          </div>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default NewEvaluation;
