interface Props {}

const IconPDF: React.FC<Props> = props => {
  return (
    <svg
      version='1.1'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 18 18'
    >
      <g>
        <path
          fillRule='evenodd'
          clipRule={'evenodd'}
          d='M9.6,9.2L9.6,9.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1C8.7,9.4,8.6,9.6,8.5,9.8c0,0.1,0,0.3,0,0.4v2.5
		c0,0.1,0,0.2,0,0.4c0,0.2,0.1,0.4,0.4,0.5c0.1,0.1,0.3,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c1.3,0,2.3-1,2.3-2.2
		C11.9,10.2,10.9,9.2,9.6,9.2z M9.6,12.8H9.4V10h0.2c0.8,0,1.4,0.6,1.4,1.4S10.4,12.8,9.6,12.8z M15.3,9.2h-1c-0.3,0-0.5,0-0.7,0
		c-0.2,0-0.4,0.1-0.6,0.3c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.2,0,0.4,0,0.7v2.4c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-1.4h1.1
		c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-1.1v-0.2c0-0.3,0-0.5,0-0.6c0,0,0-0.1,0-0.1l0-0.1l0.1,0c0,0,0,0,0.1,0
		c0.1,0,0.3,0,0.6,0h0.9c0.2,0,0.4-0.2,0.4-0.4C15.7,9.4,15.5,9.2,15.3,9.2z M6.4,9.2H5.9l0-0.1v0.1c-0.2,0-0.3,0-0.4,0
		C5.3,9.2,5.1,9.3,5,9.4S4.8,9.7,4.8,9.9c0,0.1,0,0.3,0,0.4v2.9c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-1.4h0.8
		c0.8,0,1.4-0.6,1.4-1.3S7.2,9.2,6.4,9.2z M6.4,11H5.6v-0.8l0-0.1l0.1,0h0.7C6.8,10,7,10.3,7,10.5S6.8,11,6.4,11z'
        />
      </g>
      <g>
        <path
          fillRule='evenodd'
          clipRule={'evenodd'}
          d='M15,5.8v1.6c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4V5.9c0-0.6,0-1,0-1.4c0-0.3-0.1-0.6-0.1-0.8
		c-0.3-0.7-0.8-1.2-1.5-1.4c-0.4-0.2-1-0.2-2.3-0.2c-2.4,0-3.4,0-4.2,0.3C4.6,2.9,3.5,3.9,3,5.1C2.9,5.5,2.8,5.9,2.8,6.5
		c0,0.6,0,1.4,0,2.5v1.8c0,1.1,0,1.9,0.1,2.5c0.1,0.6,0.2,1,0.4,1.3c0.1,0.2,0.3,0.4,0.5,0.5c0.3,0.2,0.8,0.4,1.4,0.4
		c0.7,0.1,1.5,0.1,2.7,0.1h3.1c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4H7.8c-1.2,0-2.1,0-2.8-0.1c-0.8-0.1-1.4-0.3-1.8-0.6
		c-0.3-0.2-0.5-0.4-0.7-0.7c-0.3-0.4-0.5-1-0.6-1.7c-0.1-0.7-0.1-1.5-0.1-2.6V9c0-1,0-1.9,0-2.5C2,5.8,2,5.2,2.2,4.8
		c0.6-1.5,1.8-2.7,3.4-3.2c1-0.4,2.2-0.4,4.2-0.4h0.5c1.1,0,1.8,0,2.4,0.2c1,0.3,1.7,1,2.1,1.9c0.1,0.3,0.2,0.6,0.2,1
		C15,4.8,15,5.3,15,5.8z'
        />
      </g>
      <g>
        <path
          fillRule='evenodd'
          clipRule={'evenodd'}
          d='M9.9,1.7c0,0.2-0.2,0.4-0.4,0.4c-1.1,0-2,0.9-2,2c0,0.1,0,0.2,0,0.4l0,0c0,0.1,0,0.2,0,0.3
		c0,0.2,0,0.6-0.1,0.9c-0.1,0.6-0.6,1-1.2,1.2C6.1,6.9,5.9,7,5.6,7H5.5c-0.1,0-0.2,0-0.4,0l0,0c-0.1,0-0.2,0-0.4,0c-1.1,0-2,0.9-2,2
		c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4C1.9,7.3,3.1,6,4.7,6C4.8,6,5,6,5.1,6l0.1,0c0.1,0,0.2,0,0.3,0h0.1
		c0.2,0,0.4,0,0.5,0C6.4,6,6.6,5.7,6.6,5.5c0-0.2,0.1-0.3,0-0.6c0-0.1,0-0.2,0-0.3v0c0-0.1,0-0.3,0-0.4c0-1.6,1.3-2.8,2.8-2.8
		C9.8,1.2,9.9,1.4,9.9,1.7z'
        />
      </g>
    </svg>
  );
};

export default IconPDF;
