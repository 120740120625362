import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { previewContractTemplate } from '../../redux/contractTemplates/contractTemplatesThunk';
import { useAppDispatch } from '../../redux/hooks';
import useHelper from '../../helpers/useHelper';
import {
  ContractTemplateForm,
  ContractTemplateListItem,
  ContractTemplatePreview,
} from '../../models/ContractTemplates';
import IconTimes from '../../icons/IconTimes';
import Spinner from '../Spinner';
import './ModalPreviewTemplate.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showModal: boolean;
  item: ContractTemplateListItem | ContractTemplateForm;
  hideModal: () => void;
}

const ModalPreviewTemplate: React.FC<Props> = ({
  item,
  showModal,
  hideModal,
}) => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const [base64Data, setBase64Data] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    preview(item);
    // eslint-disable-next-line
  }, [item]);

  const preview = async (template: ContractTemplatePreview) => {
    try {
      const resultPreview = await dispatch(
        previewContractTemplate({
          ...(item?.id ? { id: item?.id } : {}),
          ...('content' in item ? { content: item?.content } : {}),
        }),
      );
      unwrapResult(resultPreview);

      const blob = resultPreview.payload.data;

      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setBase64Data(`${reader.result}#toolbar=0&navpanes=0&scrollbar=0`);
        } else {
          console.error('Error: FileReader result is not a string');
        }
      };
      reader.readAsDataURL(blob);
    } catch (err: any) {
      hideModal();
      diplayErrorMessages(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={hideModal}
        animation={false}
        className='doc-modal'
      >
        <button
          className='close-modal'
          aria-label='Închide'
          onClick={hideModal}
        >
          <IconTimes />
        </button>
        <Modal.Body>
          <div
            style={{
              width: '100%',
              height: '500px',
            }}
          >
            {loading ? (
              <Spinner />
            ) : base64Data ? (
              <iframe
                src={base64Data}
                title='PDF Preview'
                style={{
                  width: '100%',
                  height: '500px',
                  border: '1px solid #ccc',
                }}
              ></iframe>
            ) : (
              <p>No PDF yet.</p>
            )}{' '}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPreviewTemplate;
