import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ContractTemplateListItem,
  ContractTemplatePreview,
  ContractTemplateRequestDto,
} from '../../models/ContractTemplates';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

type ContractTemplatesResponse = {
  contractTemplates: ContractTemplateListItem[];
  totalCount: number;
};

export const getContractTemplates = createAsyncThunk<
  ContractTemplatesResponse,
  any
>('crm/getContracTemplates', async (data, { rejectWithValue }) => {
  try {
    const response = await appAxios.get('api/v1/crm/contracts-templates', {
      params: data,
    });
    const totalCount = response.headers['x-total-count'];

    return { contractTemplates: response.data, totalCount };
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const getContractTemplate = createAsyncThunk<any, any>(
  'crm/contractTemplate',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        `api/v1/crm/contracts-templates/${id}`,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const addContractTemplate = createAsyncThunk<
  any,
  ContractTemplateRequestDto
>('crm/contractTemplate/add', async (contractTemplate, { rejectWithValue }) => {
  try {
    const response = await appAxios.post(
      `api/v1/crm/contracts-templates`,
      contractTemplate.body,
    );

    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const editContractTemplate = createAsyncThunk<
  any,
  ContractTemplateRequestDto
>(
  'crm/contractTemplate/edit',
  async (contractTemplate, { rejectWithValue }) => {
    try {
      const response = await appAxios.put(
        `api/v1/crm/contracts-templates/${contractTemplate.id}`,
        contractTemplate.body,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const deleteContractTemplate = createAsyncThunk<
  ContractTemplateListItem[],
  any
>('crm/contractTemplate/delete', async (id, { rejectWithValue }) => {
  try {
    const response = await appAxios.delete(
      `api/v1/crm/contracts-templates/${id}`,
    );

    return { ...response.data, id };
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const getContractTemplateFields = createAsyncThunk<any>(
  'crm/getContractTemplateFields',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        'api/v1/crm/contracts-templates-fields',
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const previewContractTemplate = createAsyncThunk<
  any,
  ContractTemplatePreview
>('crm/previewContractTemplate', async (data, { rejectWithValue }) => {
  try {
    const response = await appAxios.post(
      'api/v1/crm/contracts-templates/preview',
      data,
      { responseType: 'blob' },
    );

    return response;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});
