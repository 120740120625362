import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getFilePath } from '../../helpers/useHelper';
import { UserProfile } from '../../models/AgentsDto';
import IconArrowLeft from '../../icons/IconArrowLeft';
import IconEnvelope from '../../icons/IconEnvelope';
import IconHome from '../../icons/IconHome';
import IconPhone from '../../icons/IconPhone';
import IconUploadPhoto from '../../icons/IconUploadPhoto';
import './AgentInfo.scss';

interface Props {
  user: UserProfile | null;
}

const AgentInfo: React.FC<Props> = ({ user }) => {
  return (
    <div className='agent-info-container'>
      <div className='form-container'>
        <div className='img-upload-block'>
          <div className='image-container'>
            <div className='upload-container'>
              <label htmlFor='file-upload' className='custom-file-upload'>
                {user?.profile?.avatar?.path ? (
                  <img src={getFilePath(user?.profile?.avatar?.path)} alt='' />
                ) : (
                  <IconUploadPhoto />
                )}
              </label>
            </div>
          </div>
          <div className='radion-block'>
            <div
              className={`label-info label-info-${user?.status === 1 ? 'a' : 'ina'}ctive`}
            >
              {user?.status === 1 ? 'A' : 'Ina'}ctiv
            </div>
          </div>
        </div>
        <div className='user-info-container'>
          <div className='user-name'>{user?.profile?.fullName}</div>
          {user?.profile?.phoneNumbers &&
            user?.profile?.phoneNumbers.filter(phone => phone).length > 0 && (
              <div className='user-info-item'>
                <IconPhone />
                <span>
                  {'Tel: '}
                  {user?.profile?.phoneNumbers?.map(phoneNumber => (
                    <a
                      href={`tel:${phoneNumber}`}
                      aria-label=''
                      className='number'
                    >
                      {phoneNumber}
                    </a>
                  ))}
                </span>
              </div>
            )}
          <div className='user-info-item'>
            <IconEnvelope />{' '}
            <span>
              {'E-mail: '}
              <a href={`mailto:${user?.email}`} aria-label='' className='mail'>
                {user?.email}
              </a>
            </span>
          </div>
          {(user?.profile?.county || user?.profile?.town) && (
            <div className='user-info-item'>
              <IconHome />
              {user.profile.county && (
                <span>{`Judet: ${user.profile.county.title}`}</span>
              )}
              {user.profile.town && (
                <span>{`Oras: ${user.profile.town.title}`}</span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentInfo;
