import { createAsyncThunk } from '@reduxjs/toolkit';
import { Citizenship } from '../../models/Citizenship';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export type CitizenshipsReponse = {
  citizenships: Citizenship[];
  totalCount: number;
};

export const getCitizenships = createAsyncThunk<CitizenshipsReponse, any>(
  'crm/getCitizenships',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/citizenships', {
        params: data,
      });
      const totalCount = response.headers['x-total-count'];

      return { citizenships: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
