interface Props {}

const IconSearchL: React.FC<Props> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      {...props}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 11C5 7.691 7.691 5 11 5C14.309 5 17 7.691 17 11C17 14.309 14.309 17 11 17C7.691 17 5 14.309 5 11ZM20.707 19.293L17.312 15.897C18.365 14.543 19 12.846 19 11C19 6.589 15.411 3 11 3C6.589 3 3 6.589 3 11C3 15.411 6.589 19 11 19C12.846 19 14.543 18.365 15.897 17.312L19.293 20.707C19.488 20.902 19.744 21 20 21C20.256 21 20.512 20.902 20.707 20.707C21.098 20.316 21.098 19.684 20.707 19.293Z'
        fill='#1D1956'
      />
      <path
        d='M6 11C6 10.7239 6.22386 10.5 6.5 10.5H15.5C15.7761 10.5 16 10.7239 16 11C16 11.2761 15.7761 11.5 15.5 11.5H6.5C6.22386 11.5 6 11.2761 6 11Z'
        fill='#1D1956'
      />
      <path
        d='M8 9C8 8.72386 8.22386 8.5 8.5 8.5H13.5C13.7761 8.5 14 8.72386 14 9C14 9.27614 13.7761 9.5 13.5 9.5H8.5C8.22386 9.5 8 9.27614 8 9Z'
        fill='#1D1956'
      />
      <path
        d='M8 13C8 12.7239 8.22386 12.5 8.5 12.5H13.5C13.7761 12.5 14 12.7239 14 13C14 13.2761 13.7761 13.5 13.5 13.5H8.5C8.22386 13.5 8 13.2761 8 13Z'
        fill='#1D1956'
      />
    </svg>
  );
};

export default IconSearchL;
