import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getEvaluationPdf } from '../../redux/evaluations/evaluationsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Evaluation, EvaluationBody } from '../../models/Evaluation';
import IconPDF from '../../icons/IconPDF';
import IconSearchL from '../../icons/IconSearchL';
import { roomsData } from '../FilterCategory/FilterForm/FiltersForm';
import ModalPDF from '../ModalPDF';
import './PriceEvaluationGrafic.scss';

interface Props {
  evaluation: Evaluation;
  evaluationBody: EvaluationBody;
  formFilters: any[];
}

const PriceEvaluationGrafic: React.FC<Props> = ({
  evaluation,
  formFilters,
  evaluationBody,
}) => {
  const dispatch = useAppDispatch();
  const { locations, zona, specificTypes } = useAppSelector(
    state => state.localities,
  );

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const getFilterValue = (filter: any) => {
    switch (filter.type) {
      case 'SELECT_OPTIONS':
        return formFilters?.[filter.id]?.value;
      case 'SELECT_OPTIONS_ROOMS': {
        return roomsData
          .filter(room => formFilters?.[filter.id]?.includes(room.value))
          .map(room => room.label)
          .join(',');
      }
      case 'OFFER_TYPE':
        return formFilters?.[filter.id]?.value === '1'
          ? 'Vanzare'
          : formFilters?.[filter.id]?.value === '2'
            ? 'Inchiriere'
            : 'Regim hotelier';
      case 'LOCATION':
        const filteredLocations = locations.filter(location =>
          filter.value.includes(location.id.toString()),
        );
        let location = filteredLocations
          .slice(0, 2)
          .map(location => location.title)
          .join(', ');
        if (filteredLocations.length > 2) {
          location += '...';
        }
        return location;
      case 'SELECT':
      case 'COUNTY':
      case 'TOWN':
        return formFilters?.[filter.id]?.label;
      case 'ZONE':
        const filteredZones = zona.filter(zone =>
          filter.value.includes(zone.id.toString()),
        );
        let zones = filteredZones
          .slice(0, 2)
          .map(zone => zone.title)
          .join(', ');
        if (filteredZones.length > 2) {
          zones += '...';
        }
        return zones;
      case 'ID':
        const filteredTypes = specificTypes.filter(tip =>
          filter.value.includes(tip.id.toString()),
        );
        let types = filteredTypes
          .slice(0, 2)
          .map(tip => tip.title)
          .join(', ');
        if (filteredTypes.length > 2) {
          types += '...';
        }
        return types;
      case 'INPUT':
      case 'OWNER_PHONE':
      case 'STREET_NUMBER':
      case 'KEYWORDS':
        return formFilters?.[filter.id];
      case 'RANGE':
      case 'PRICE':
        return `${formFilters?.[filter.id]?.from} - ${formFilters?.[filter.id]?.to}`;
      case 'RANGE_SELECT':
        return `${formFilters?.[filter.id]?.from?.label} ${formFilters?.[filter.id]?.to?.label ? ' - ' + formFilters?.[filter.id]?.to?.label : ''}`;
      case 'CHECKBOX':
      case 'VIRTUAL_TUR_CHECKBOX':
      case 'VIDEO_CHECKBOX':
      case 'FLOOR_EXCLUDED_NOT_SPECIFIC_CHECKBOX':
      case 'FLOOR_EXCLUDED_P_LAST_CHECKBOX':
      case 'HAS_CONTRACT_DATA_CHECKBOX':
      case 'EXCLUSIVENESS_CHECKBOX':
        return formFilters?.[filter.id] === 1 ||
          formFilters?.[filter.id] === true
          ? 'Da'
          : 'Nu';
      default:
        return null;
    }
  };

  const downloadPDF = async () => {
    try {
      const response = await dispatch(getEvaluationPdf(evaluationBody));
      unwrapResult(response);

      const blob = response.payload.data;

      const reader = new FileReader();

      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const a = document.createElement('a');
          a.href = reader.result;
          a.setAttribute(
            'download',
            'Evaluation_' + moment(new Date()).format('DD-MM-YYYY'),
          );
          document.body.appendChild(a);
          a.click();
          a.parentNode?.removeChild(a);
          toast.success('PDF exportat cu succes!');
        } else {
          toast.error('Eroare la descarcare PDF');
        }
      };
      reader.readAsDataURL(blob);
    } catch (err: any) {
      toast.error('Eroare la descarcare PDF: ' + err);
    }
  };

  return (
    <div className='bg-pink price-evaluation-grafic'>
      <div className='price-evaluation-grafic-container'>
        <form action=''>
          <div className='search-container'>
            <label htmlFor='imobil-price' className='form-item-group-label'>
              Evaluare Pret Imobil
            </label>
            <div className='form-item-group-block '>
              <input
                type='search'
                className='form-item-control'
                placeholder={`Au fost analizate ${evaluation.processedAnnounces} anunturi`}
                name='query'
                id='imobil-price'
                value={''}
                readOnly
              />
              <button className='icon' aria-label='Cauta' type='submit'>
                <IconSearchL />
              </button>
            </div>
          </div>
        </form>
        {evaluation.filters?.length > 0 && (
          <div className='applied-filtres'>
            <p className='heading-lg'>Filtre aplicate</p>
            <div className='applied-filtres-list'>
              {evaluation.filters?.map(item => (
                <div className='applied-filtres-list__item'>
                  {item?.title && (
                    <div className='applied-filtres-list__item-title'>
                      {`${item.title}:`}
                    </div>
                  )}
                  {item?.value && (
                    <div className='applied-filtres-list__item-label'>
                      <span className='label-item-tag'>{`${getFilterValue(item)}`}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className='price-evaluation-grafic__table'>
          <table>
            <tr>
              <th colSpan={3}>
                <p className='heading-lg'>Evaluare Pretului de Piata</p>
              </th>
            </tr>
            <tr>
              <td className='td-left'>
                <span className='label-red'>+15%</span>
                <p>PREȚ SUPRAEVALUAT</p>
              </td>
              <td>
                <span className='triangle triangle-top-red'></span>
              </td>
              <td className='td-right '>
                <span className='label-red'>1%</span>
              </td>
            </tr>
            <tr>
              <td className='td-left'>
                <span className='label-red'>+10%</span>
                <p>PREȚ MARE</p>
              </td>
              <td>
                <span className='triangle triangle-second-red'></span>
              </td>
              <td className='td-right'>
                <span className='label-red'>10%</span>
              </td>
            </tr>
            <tr>
              <td className='td-left'>
                <span className='label-yellow'>+5%</span>
                <p>PREȚ MEDIU DE PIATA</p>
                <span className='label-yellow'>-5%</span>
              </td>
              <td>
                <span className='triangle triangle-third-yellow'>
                  <span className='label-white'>{`${evaluation.medianPrice} €`}</span>
                </span>
              </td>
              <td className='td-right'>
                <div className='table-flex'>
                  <div className='text-block'>
                    <p>Probabilitatea de Vanzare (In Decurs de 60 de Zile)</p>
                  </div>
                  <div className='lable-list'>
                    <span className='label-yellow'>50%</span>

                    <span className='label-yellow'>50%</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className='td-left'>
                <p>PREȚ BUN</p>
                <span className='label-green'>-5%</span>
              </td>
              <td>
                <span className='triangle triangle-fourth-green'></span>
              </td>
              <td className='td-right'>
                <span className='label-green'>70%</span>
              </td>
            </tr>
            <tr>
              <td className='td-left'>
                <p>PREȚ SUPER BUN</p>
                <span className='label-green'>-15%</span>
              </td>
              <td>
                <span className='triangle triangle-fifth-green'></span>
              </td>
              <td className='td-right'>
                <span className='label-green'>99%</span>
              </td>
            </tr>
          </table>
        </div>

        <div className='conclusions-evaluation'>
          <p className='heading-lg'>Concluzii Evaluare</p>
          <div className='conclusions-evaluation__list conclusions-evaluation__list-left'>
            <div className='conclusions-evaluation-list__item'>
              <div className='conclusions-evaluation-list__item-title'>
                Preț imobil
              </div>
              <div className='conclusions-evaluation__item-label'>
                <span className='label-item-tag'>{`€ ${evaluation.medianPrice}`}</span>
              </div>
            </div>
            <div className='conclusions-evaluation-list__item'>
              <div className='conclusions-evaluation-list__item-title'>
                {'Preț m²'}
              </div>
              <div className='conclusions-evaluation__item-label'>
                <span className='label-item-tag'>{`€ ${evaluation.sqmPrice}`}</span>
              </div>
            </div>
          </div>
          <div className='conclusions-evaluation__list conclusions-evaluation__list-right'>
            <div className='conclusions-evaluation-list__item'>
              <div className='conclusions-evaluation-list__item-title'>
                {'Preț m² SubEvaluat cu'}
              </div>
              <div className='conclusions-evaluation__item-label'>
                <span className='label-item-tag'>{`€ ${evaluation.sqmSubEvaluationPrice}`}</span>
              </div>
            </div>
            <div className='conclusions-evaluation-list__item'>
              <div className='conclusions-evaluation-list__item-title'>
                {'Preț m² Recomandat'}
              </div>
              <div className='conclusions-evaluation__item-label'>
                <span className='label-item-tag'>{`€ ${evaluation.sqmRecommendedPrice}`}</span>
              </div>
            </div>
            <div className='conclusions-evaluation-list__item'>
              <div className='conclusions-evaluation-list__item-title'>
                Preț Recomandat
              </div>
              <div className='conclusions-evaluation__item-label'>
                <span className='label-item-tag'>{`€ ${evaluation.recommendedPrice?.from ?? evaluation.recommendedPriceFrom} - € ${evaluation.recommendedPrice?.to ?? evaluation.recommendedPriceTo}`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='price-evaluation-grafic__buttons'>
          <button
            className='button button-border'
            onClick={e => {
              e.preventDefault();
              downloadPDF();
            }}
          >
            <IconPDF /> Descarca PDF
          </button>
        </div>
      </div>

      {showPreviewModal && (
        <ModalPDF
          showModal={showPreviewModal}
          item={evaluationBody}
          hideModal={() => setShowPreviewModal(false)}
        />
      )}
    </div>
  );
};

export default PriceEvaluationGrafic;
