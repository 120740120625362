import moment from 'moment';
import { useEffect, useState } from 'react';
import './PricesHistoryChart.scss';
import {  Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Chart from 'react-apexcharts';

interface Props {
  prices: any;
}

const PricesHistoryChart: React.FC<Props> = ({ prices }) => {
  const [data, setData] = useState<any>([]);
  const [dates, setDates] = useState<string[]>([]);

  useEffect(() => {
    let listData: any[] = [];
    let list: any[] = [];

    prices.forEach((price: any) => {
      let name = '';
      switch (price.id) {
        case 1:
          name = 'Vanzare';
          break;
        case 2:
          name = 'Inchiriere';
          break;
        case 3:
          name = 'Regim hotelier';
          break;
        case 4:
          name = 'Vanzare MP';
          break;
        case 5:
          name = 'Inchiriere MP';
          break;
        default:
          name = '';
      }
      price.history.forEach((date: any) => {
        if (!dates.includes(moment(date.dateCreated).format('DD-MM-YYYY'))) {
          list.push(moment(date.dateCreated).format('DD-MM-YYYY'));
        }
      });
      listData.push({
        name: name,
        data: price.history?.map((value: any) => value.price),
      });
    });
    setDates(list);
    setData(listData);
  }, [prices]);
  
  const graph = {
    options: {
      chart:{
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
        dataLabels: {
          enabled: false,
        },
        zoom:{
          enabled:false
        },
  
        stroke: {
          width: [4, 4],
        },
        legend: {
          offsetX: 40,
        },
      },
      colors: ['#33475b', '#33475b'],

      plotOptions: {
        bar: {
          columnWidth: '20%',
        },
      },
      xaxis: {
        categories: dates,
      },
      yaxis: [
        {
          axisBorder: {
            show: true,
            color: '#33475b',
          },
          labels: {
            style: {
              colors: '#33475b',
            },
          },
          title: {
            text: 'Pret',
            style: {
              color: '#33475b',
            },
          },
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        followCursor:false,
        x: {
          show: false,
        },
      },
     
    },
    series: data,
  };
  
  return (
    <div className='prices-history-container'>
       <Row className='grafic-container'>
      <Col lg='12'>
        <div className='grafic-block'>
          {(prices.filter((price:any) => price?.history?.length > 0) &&
            prices.filter((price:any) => price?.history?.length > 0)
              ?.length > 0) ?(
              <Chart
                options={graph.options}
                series={graph.series}
                type='line'
                width='100%'
              />
            ):<h2>Nu sunt date.</h2>}
        </div>
      </Col>
    </Row>
    </div>
  );
};

export default PricesHistoryChart;
