import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './PricesHistoryModal.scss';
import PricesHistoryChart from '../PricesHistoryChart';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  prices: any;
}

const PricesHistoryModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  prices,
}) => {  
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'mobile-modal prices-modal'}
      >
        <Modal.Body>
        <PricesHistoryChart prices={prices} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PricesHistoryModal;
