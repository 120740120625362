import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '../../assets/svg/closemodal.svg';
import ActionsMobile from '../ActionsMobile';
import FilterListPriceEvaluation from '../FilterListPriceEvaluation/FilterListPriceEvaluation';
import SearchMobile from '../SearchMobile';
import './PriceEvaluationRequestModal.scss';

interface Props {
  isModalOpen: boolean;
  isAction: boolean;
  isSearch: boolean;
  isFilter: boolean;
  filtersForm: any;
  loadingBtn?: boolean;
  setLoadingBtn?: (e: boolean) => void;
  setFiltersForm: (e: any) => void;
  sortProperty?: (field: string) => void;
  sort?: string;
  activeCategory?: number;
  setActiveCategory?: (e: number) => void;
  closeModal: () => void;
}

const PriceEvaluationRequestModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  isAction,
  isSearch,
  isFilter,
  filtersForm,
  setFiltersForm,
  loadingBtn,
  setLoadingBtn,
  sort,
  sortProperty,
  activeCategory,
  setActiveCategory,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={
          isFilter || isAction ? 'mobile-modal filter-overflow' : 'mobile-modal'
        }
      >
        <Modal.Body>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          {isSearch && <SearchMobile />}
          {isAction && <ActionsMobile />}
          {isFilter && (
            <FilterListPriceEvaluation
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PriceEvaluationRequestModal;
