import React, { useEffect, useState } from 'react';
import Select, { type DropdownIndicatorProps, components } from 'react-select';
import { showError } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import IconArrowRadius from '../../icons/IconArrowRadius';
import './RadiusSelect.scss';

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <IconArrowRadius className='rotate' />
    </components.DropdownIndicator>
  );
};

const RadiusSelect: React.FC<any> = ({
  options = [],
  labelText,
  inlineLabel,
  id,
  key,
  value,
  className,
  error,
  ...props
}) => {
  const [values, setValues] = useState<SelectValue | null>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValues(value === '' ? null : value);
  }, [value]);

  return (
    <>
      <div className='form-item-group' key={key}>
        <Select
          onChange={(e: SelectValue) => {
            setValues(e);
          }}
          options={options}
          value={values}
          {...props}
          isSearchable={false}
          components={{ DropdownIndicator: DropdownIndicator }}
          className={`${className} ${error ? 'error' : ''}`}
          menuIsOpen={open}
          onMenuOpen={() => setOpen(true)}
          onMenuClose={() => {
            setOpen(false);
          }}
          classNames={{
            control: state =>
              state.isFocused
                ? 'form-item-control-select'
                : 'form-item-control-select',
          }}
          classNamePrefix='react-select'
        />
        {error && <p className='error-message'>{showError(error)}</p>}{' '}
      </div>
    </>
  );
};

export default RadiusSelect;
