import { createSlice } from '@reduxjs/toolkit';
import { ReportEstateItem, ReportItem } from '../../models/Report';
import { getAgentsReports, getEstateReports } from './reportsThunk';

type ReportState = {
  agentsStatistics: ReportItem[];
  loadingReport: boolean;
  estateDemands: ReportEstateItem[];
  error: any;
};

const initialState: ReportState = {
  agentsStatistics: [],
  loadingReport: false,
  error: null,
  estateDemands: [],
};

const currenciesSlice = createSlice({
  name: 'agentsStatistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAgentsReports.pending, state => {
        state.loadingReport = true;
        state.error = null;
      })
      .addCase(getAgentsReports.fulfilled, (state, { payload }) => {
        state.agentsStatistics = payload;
        state.loadingReport = false;
        state.error = null;
      })
      .addCase(getAgentsReports.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingReport = false;
        state.agentsStatistics = [];
      })
      .addCase(getEstateReports.pending, state => {
        state.loadingReport = true;
        state.error = null;
      })
      .addCase(getEstateReports.fulfilled, (state, { payload }) => {
        state.estateDemands = payload;
        state.loadingReport = false;
        state.error = null;
      })
      .addCase(getEstateReports.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingReport = false;
        state.estateDemands = [];
      });
  },
});

export default currenciesSlice.reducer;
