import React from 'react';
import Form from 'react-bootstrap/Form';
import './FilterListAlerts.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  setSort: (field: string) => void;
  sort: string;
}

const FilterListAlerts: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  sort,
  setSort,
}) => {
  return (
    <div className='filter-list-container filter-list-alerts'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />{' '}
        </Form.Group>
        <div
          className={`filter-item arrows-label-icon ${sort === 'id' ? 'active-bottom' : ''} ${sort === '-id' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'id'
              ? setSort('-id')
              : sort === '-id'
                ? setSort('')
                : setSort('id')
          }
        >
          <label>ID</label>
          {/* <Input inlineLabel='ID' placeholder='Search' labelText={true} /> */}
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'title' ? 'active-bottom' : ''} ${sort === '-title' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'title'
              ? setSort('-title')
              : sort === '-title'
                ? setSort('')
                : setSort('title')
          }
        >
          <label>Titlu</label>
          {/* <Input inlineLabel='Titlu' placeholder='Cauta' labelText={true} /> */}
        </div>
        <div className='filter-item'>
          <label>Anunturi</label>
          {/* <Input inlineLabel='Anunturi' placeholder='Search' labelText={true} /> */}
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'dateViewed' ? 'active-bottom' : ''} ${sort === '-dateViewed' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'dateViewed'
              ? setSort('-dateViewed')
              : sort === '-dateViewed'
                ? setSort('')
                : setSort('dateViewed')
          }
        >
          <label>Ultima vizualizare</label>
          {/* <div className='flex-input'>
            <Input placeholder='De la' labelText={false} />
            <Input placeholder='Pana la' labelText={false} />
          </div> */}
        </div>
        <div className='filter-item'>
          <label>Actiuni</label>
        </div>
      </form>
    </div>
  );
};

export default FilterListAlerts;
