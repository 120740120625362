import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { useEffect, useState } from 'react';
import FixedActions from '../../components/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ReportsAgentsTable from '../../components/ReportsAgentsTable';
import ReportsFilter from '../../components/ReportsFilter';
import ReportsModal from '../../components/ReportsModal';
import SideNav from '../../components/SideNav';
import { getActivitiesTypes } from '../../redux/activities/activitiesThunk';
import { useAppDispatch } from '../../redux/hooks';
import { getAgentsReports } from '../../redux/reports/reportsThunk';
import useHelper, { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './ReportsAgents.scss';

interface Props {}

const ReportsAgents: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  //eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.Reports),
  );
  const [filtersForm, setFiltersForm] = useState<any>({
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };
  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };
  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getActivitiesTypes());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getReports();
    //eslint-disable-next-line
  }, [filtersForm]);

  const getReports = async () => {
    try {
      const response = await dispatch(getAgentsReports(filtersForm));
      unwrapResult(response);
    } catch (err) {
      diplayErrorMessages(err);
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Raport Agenti'
          subtitle='Vezi evolutia proprietatilor si a cererilor adaugate'
          isbutton={false}
          btnText=''
        />
        {havePermission ? (
          <div className='display-flex flex-column '>
            <ReportsFilter
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
            />
            <ReportsAgentsTable />
            <FixedActions
              disabledActions={true}
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <ReportsModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
            />
          </div>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default ReportsAgents;
