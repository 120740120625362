import { createSlice } from '@reduxjs/toolkit';
import {
  Contract,
  ContractDetails,
  ContractTemplate,
  ContractTemplateField,
} from '../../models/Contract';
import {
  createContract,
  exportContracts,
  getContract,
  getContractTemplate,
  getContractTemplateFields,
  getContracts,
  getContractsTemplates,
  postContractSign,
  previewContract,
  removeContracts,
  updateContract,
} from './contractsThunk';

type ContractState = {
  contractsTemplates: ContractTemplate[];
  contractTemplate: ContractTemplate | null;
  contractTemplateLoading: boolean;
  contractTemplateFields: ContractTemplateField[];
  contractTemplateFieldsLoading: boolean;
  contracts: Contract[];
  contract: ContractDetails | null;
  loading: boolean;
  error: any;
  count: number;
  signErrors:any;
};

const initialState: ContractState = {
  contractsTemplates: [],
  contracts: [],
  contract: null,
  count: 0,
  loading: false,
  error: null,
  signErrors:null,
  contractTemplate: null,
  contractTemplateLoading: false,
  contractTemplateFields: [],
  contractTemplateFieldsLoading: false,
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearContract(state) {
      state.contract = null;
    },
    clearContractTemplate(state) {
      state.contractTemplate = null;
    },
    toggleChecked(state, action) {
      const contractId = action.payload;
      const contract = state.contracts.find(
        contract => contract.id === contractId,
      );

      if (contract) {
        contract.checked = !contract.checked;
      }
    },
    checkAll(state, action) {
      state.contracts.forEach(contract => (contract.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getContractsTemplates.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContractsTemplates.fulfilled, (state, { payload }) => {
        state.contractsTemplates = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getContractsTemplates.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getContractTemplateFields.pending, state => {
        state.contractTemplateFieldsLoading = true;
        state.error = null;
      })
      .addCase(getContractTemplateFields.fulfilled, (state, { payload }) => {
        state.contractTemplateFields = payload;
        state.contractTemplateFieldsLoading = false;
        state.error = null;
      })
      .addCase(getContractTemplateFields.rejected, (state, action) => {
        state.error = action.payload;
        state.contractTemplateFieldsLoading = false;
      })

      .addCase(getContractTemplate.pending, state => {
        state.contractTemplateLoading = true;
        state.error = null;
      })
      .addCase(getContractTemplate.fulfilled, (state, { payload }) => {
        state.contractTemplate = payload;
        state.contractTemplateLoading = false;
        state.error = null;
      })
      .addCase(getContractTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.contractTemplateLoading = false;
      })

      .addCase(getContracts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContracts.fulfilled, (state, { payload }) => {
        state.contracts = payload.contracts;
        state.count = +payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getContracts.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createContract.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createContract.fulfilled, (state, { payload }) => {
        state.contract = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(createContract.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateContract.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContract.fulfilled, (state, { payload }) => {
        state.contract = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(updateContract.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getContract.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContract.fulfilled, (state, { payload }) => {
        state.contract = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getContract.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(previewContract.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(previewContract.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(previewContract.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(removeContracts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeContracts.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(removeContracts.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(exportContracts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exportContracts.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(exportContracts.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postContractSign.pending, state => {
        state.signErrors = null;
      })
      .addCase(postContractSign.fulfilled, state => {
        state.signErrors = null;
      })
      .addCase(postContractSign.rejected, (state, action) => {
        state.signErrors = action.payload;
      });
  },
});

export const {
  clearError,
  toggleChecked,
  checkAll,
  clearContract,
  clearContractTemplate,
} = contractsSlice.actions;

export default contractsSlice.reducer;
