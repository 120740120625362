interface Props {}

const IconEnvelope: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88664 9.8187C5.60862 9.8187 4.56836 8.77843 4.56836 7.50041C4.56836 6.22239 5.60862 5.18213 6.88664 5.18213C8.16466 5.18213 9.20493 6.22239 9.20493 7.50041C9.20493 8.77843 8.16466 9.8187 6.88664 9.8187ZM8.21615 0.0335046C5.96537 -0.177998 3.76409 0.619263 2.17932 2.21529C0.593796 3.81056 -0.186965 6.01784 0.0380384 8.27012C0.306542 10.9492 1.99482 13.2817 4.4451 14.3565C5.42536 14.7862 6.46488 14.997 7.49689 14.997C9.12292 14.997 10.7317 14.4727 12.06 13.4557C12.3892 13.2037 12.4515 12.7327 12.1995 12.4034C11.9475 12.0757 11.4772 12.0134 11.1479 12.2647C9.40942 13.5959 7.07239 13.8689 5.04736 12.9824C3.12133 12.1379 1.74206 10.2299 1.53056 8.12012C1.35056 6.31634 1.97457 4.54932 3.24358 3.27205C4.5111 1.99553 6.27513 1.35652 8.0774 1.52753C11.1187 1.81028 13.5 4.50807 13.5 7.66936V8.17562C13.5 8.52662 13.3605 8.85363 13.1062 9.09663C12.8527 9.33964 12.5167 9.46414 12.1665 9.44914C11.496 9.41989 10.9499 8.78913 10.9499 8.04362V4.80057C10.9499 4.38582 10.6147 4.05056 10.1999 4.05056C9.81818 4.05056 9.51667 4.33782 9.46867 4.70682C8.78766 4.07681 7.88465 3.68231 6.88638 3.68231C4.78111 3.68231 3.06808 5.39608 3.06808 7.50061C3.06808 9.60589 4.78111 11.3189 6.88638 11.3189C8.1614 11.3189 9.28567 10.6844 9.97943 9.72139C10.4632 10.4264 11.2304 10.9094 12.1005 10.9477C12.8602 10.9882 13.5907 10.7092 14.1435 10.1804C14.6962 9.65164 15 8.93988 15 8.17562V7.66936C15 3.74156 12.021 0.38826 8.21615 0.0335046Z"
        fill="#33475B"
      ></path>
    </svg>
  );
};

export default IconEnvelope;
