import { createSlice } from '@reduxjs/toolkit';
import {
  ContractTemplate,
  ContractTemplateField,
  ContractTemplateListItem,
} from '../../models/ContractTemplates';
import {
  addContractTemplate,
  deleteContractTemplate,
  editContractTemplate,
  getContractTemplate,
  getContractTemplateFields,
  getContractTemplates,
} from './contractTemplatesThunk';

type ContractTemplatesState = {
  contractTemplates: ContractTemplateListItem[];
  contractTemplate: null | ContractTemplate;
  count: number;
  contractTemplateFields: ContractTemplateField[];
  loadingTemplates: boolean;
  loadingTemplate: boolean;
  error: any;
};


const initialState: ContractTemplatesState = {
  contractTemplates: [],
  count: 0,
  contractTemplate: null,
  contractTemplateFields: [],
  loadingTemplates: false,
  loadingTemplate: false,
  error: null,
};


const contractTemplatesSlice = createSlice({
  name: 'contractTemplates',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearTemplate(state) {
      state.contractTemplate = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getContractTemplates.pending, state => {
        state.loadingTemplates = true;
        state.error = null;
      })
      .addCase(getContractTemplates.fulfilled, (state, { payload }) => {
        state.contractTemplates = payload.contractTemplates;
        state.count = payload.totalCount;
        state.loadingTemplates = false;
        state.error = null;
      })
      .addCase(getContractTemplates.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplates = false;
      })
      .addCase(getContractTemplate.pending, state => {
        state.contractTemplate = null;
        state.loadingTemplate = true;
        state.error = null;
      })
      .addCase(getContractTemplate.fulfilled, (state, { payload }) => {
        state.contractTemplate = payload;
        state.loadingTemplate = false;
        state.error = null;
      })
      .addCase(getContractTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplate = false;
      })
      .addCase(addContractTemplate.pending, state => {
        state.loadingTemplate = true;
        state.error = null;
      })
      .addCase(addContractTemplate.fulfilled, (state, { payload }) => {
        state.contractTemplate = payload;
        state.loadingTemplate = false;
        state.error = null;
      })
      .addCase(addContractTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplate = false;
      })
      .addCase(editContractTemplate.pending, state => {
        state.loadingTemplate = true;
        state.error = null;
      })
      .addCase(editContractTemplate.fulfilled, (state, { payload }) => {
        state.contractTemplate = payload;
        state.loadingTemplate = false;
        state.error = null;
      })
      .addCase(editContractTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplate = false;
      })

      .addCase(deleteContractTemplate.pending, state => {
        state.loadingTemplates = true;
        state.error = null;
      })
      .addCase(deleteContractTemplate.fulfilled, state => {
        state.loadingTemplates = false;
        state.error = null;
      })
      .addCase(deleteContractTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplates = false;
      })
      .addCase(getContractTemplateFields.fulfilled, (state, { payload }) => {
        state.contractTemplateFields = payload;
      })
    ;
  },
});

export const { clearError, clearTemplate} = contractTemplatesSlice.actions;

export default contractTemplatesSlice.reducer;
