// import moment from 'moment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useAppSelector } from '../../redux/hooks';
import useDebounce from '../../helpers/useDebounce';
import { parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import FormSelect from '../FormSelect';
import Input from '../Input';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import './FilterListContracts.scss';

interface Props {
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  onSubmit?: () => void;
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
}

interface ContractsFilterForm {
  contractTemplates?: any;
  employees?: any;
  dateCreatedFrom?: string;
  dateCreatedTo?: string;
  signStatus?: any;
  q?: string;
}

const statusOptions = [
  { value: 'completed', label: 'Semnat' },
  { value: 'draft', label: 'In draft' },
];

const FilterListContracts: React.FC<Props> = ({
  filtersForm,
  setFiltersForm,
  onSubmit,
  isCheckedAll,
  setCheckedAll,
}) => {
  const { contractsTemplates } = useAppSelector(state => state.contracts);
  const { users } = useAppSelector(state => state.users);
  const [formData, setFormData] = useState<ContractsFilterForm>(filtersForm);
  const [query, setQuery] = useState(filtersForm?.q ?? null);

  const debouncedContractSearch = useDebounce(query, 300);

  useEffect(() => {
    setFormData(filtersForm);
  }, [filtersForm]);

  useEffect(() => {
    setFormData({ ...formData, q: debouncedContractSearch });
    // eslint-disable-next-line
  }, [debouncedContractSearch]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (JSON.stringify(formData) !== JSON.stringify(filtersForm)) {
      filter(formData);
    }
    onSubmit && onSubmit();
  };

  const handleChange = (
    key: string | number,
    e: string | number | SelectValue | boolean | null | Date | string[],
  ) => {
    setFormData({ ...formData, [key]: e });
  };

  const filter = (formData: any) => {
    formData &&
      setFiltersForm({
        ...(formData?.q?.length > 0 ? { q: formData?.q } : {}),
        employees: formData?.employees,
        contractTemplates: formData?.contractTemplates,
        signStatus: formData?.signStatus?.value,
        ...(formData?.dateCreatedTo
          ? {
              dateCreatedTo: moment(formData?.dateCreatedTo ?? '').format(
                'YYYY-MM-DD',
              ),
            }
          : {}),
        ...(formData?.dateCreatedFrom
          ? {
              dateCreatedFrom: moment(formData?.dateCreatedFrom ?? '').format(
                'YYYY-MM-DD',
              ),
            }
          : {}),
      });
    setCheckedAll && setCheckedAll(false);
    onSubmit && onSubmit();
  };

  const resetForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFiltersForm({});
    setFormData({});
    setCheckedAll && setCheckedAll(false);
  };

  return (
    <div className='bg-white contracts-list-filter'>
      <form onSubmit={(event: any) => event.preventDefault()}>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            className='input-center'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
          <div className='form-item-group-label'>Selecteaza toate</div>
        </Form.Group>
        <div className='filter-item'>
          <Input
            labelText={true}
            inlineLabel={'Cautare'}
            type={'search'}
            value={query ?? ''}
            onChange={(event: any) => setQuery(event.target.value)}
            placeholder='Căutare'
          />
        </div>
        <div className='filter-item'>
          <MultiselectCheckboxes
            labelText={true}
            placeholder='Select Option'
            inlineLabel={'Tip contract'}
            isSearchable={true}
            value={formData.contractTemplates ?? []}
            options={parseValues(contractsTemplates)}
            onChange={(e: SelectValue) => handleChange('contractTemplates', e)}
          />
        </div>
        <div className='filter-item'>
          <MultiselectCheckboxes
            labelText={true}
            placeholder='Select Option'
            inlineLabel={'Agent'}
            isSearchable={true}
            value={formData.employees ?? []}
            onChange={(e: SelectValue) => handleChange('employees', e)}
            options={users && parseValues(users)}
          />
        </div>

        <div className='filter-item'>
          <FormSelect
            labelText={true}
            placeholder='Select Option'
            inlineLabel={'Status'}
            isSearchable={true}
            isClearable={true}
            value={formData.signStatus ?? null}
            onChange={(e: SelectValue) => handleChange('signStatus', e)}
            options={statusOptions}
          />
        </div>
        <div className='form-group filter-item' key={0}>
          <label className='form-item-group-label'>Data</label>
          <div className='flex-input'>
            <div className='form-item'>
              <DatePicker
                selected={
                  formData.dateCreatedFrom
                    ? new Date(formData.dateCreatedFrom)
                    : null
                }
                placeholderText='De la'
                onChange={e => handleChange('dateCreatedFrom', e)}
              />
            </div>
            <div className='form-item'>
              <DatePicker
                selected={
                  formData.dateCreatedTo
                    ? new Date(formData.dateCreatedTo)
                    : null
                }
                placeholderText='Pana la'
                onChange={e => handleChange('dateCreatedTo', e)}
              />
            </div>
          </div>
        </div>

        <div className='form-btns-section'>
          <button className='reset-btn' onClick={e => resetForm(e)}>
            Reseteaza
          </button>
          <button
            className='filter-btn btn-blue'
            onClick={e => handleSubmit(e)}
          >
            Filtreaza
          </button>
        </div>
      </form>
    </div>
  );
};

export default FilterListContracts;
