import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  createSuggestion,
  getSuggestionSubjects,
} from '../../redux/suggestions/suggestionsThunk';
import useHelper, {
  getValue,
  parseValues,
  showError,
} from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import IconClose from '../../icons/IconClose';
import FormSelect from '../FormSelect';
import Input from '../Input';
import Spinner from '../Spinner';
import './SuggestModal.scss';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}
interface Form {
  name?: string;
  email?: string;
  phone?: string;
  subjectId?: string | number | null;
  message?: string;
}

const SuggestModal: React.FC<Props> = ({ isModalOpen, closeModal }) => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const { suggestionsSubjects, loadingSubjects, error } = useAppSelector(
    state => state.suggestions,
  );
  const [formData, setFormData] = useState<Form>({
    name: '',
    phone: '',
    email: '',
    message: '',
    subjectId: null,
  });

  useEffect(() => {
    dispatch(getSuggestionSubjects());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async () => {
    try {
      const response = await dispatch(
        createSuggestion({
          ...formData,
          ...(formData.subjectId ? { subjectId: +formData.subjectId } : {}),
        }),
      );
      unwrapResult(response);

      if (response.payload?.id) {
        toast.success('Sugestia a fost completata cu succes!');
        setFormData({
          name: '',
          phone: '',
          email: '',
          message: '',
          subjectId: null,
        });
        closeModal();
      }
    } catch (err) {
      diplayErrorMessages(err);
    }
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'suggest-modal'}
      >
        <Modal.Body>
          {loadingSubjects ? (
            <div
              style={{
                minHeight: '200px',
                padding: '20px',
                position: 'relative',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <>
              <div className='suggest-modal__top'>
                <p>Lasă sugestia sau comentariul</p>
                <div className='close-btn-modal' onClick={closeModal}>
                  <IconClose />
                </div>
              </div>
              <div className='suggest-modal__form'>
                <div className='form-item'>
                  <Input
                    labelText={true}
                    value={formData?.name}
                    classInput={error?.name ? 'error' : ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    inlineLabel='Nume'
                  />
                  {error?.name && (
                    <p className='error-message'>{showError(error.name)}</p>
                  )}
                </div>
                <div className='form-item form-item-half'>
                  <Input
                    labelText={true}
                    value={formData?.phone}
                    type='tel'
                    classInput={error?.phone ? 'error' : ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormData({
                        ...formData,
                        phone: e.target.value,
                      });
                    }}
                    inlineLabel='Telefon'
                  />
                  {error?.phone && (
                    <p className='error-message'>{showError(error.phone)}</p>
                  )}
                </div>

                <div className='form-item form-item-half'>
                  <Input
                    labelText={true}
                    value={formData?.email}
                    classInput={error?.email ? 'error' : ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormData({
                        ...formData,
                        email: e.target.value,
                      })
                    }
                    inlineLabel='Email'
                  />
                  {error?.email && (
                    <p className='error-message'>{showError(error.email)}</p>
                  )}
                </div>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Selecteaza'
                    inlineLabel={'Subiect'}
                    isSearchable={true}
                    className={error?.subjectId ? 'error' : ''}
                    value={
                      formData.subjectId &&
                      getValue(suggestionsSubjects, formData.subjectId)
                    }
                    options={
                      suggestionsSubjects.length > 0
                        ? parseValues(suggestionsSubjects)
                        : []
                    }
                    onChange={(e: SelectValue) =>
                      setFormData({ ...formData, subjectId: e.value })
                    }
                  />
                  {error?.subjectId && (
                    <p className='error-message'>
                      {showError(error.subjectId)}
                    </p>
                  )}
                </div>
                <div className='form-item-group'>
                  <label className='form-item-group-label' htmlFor=''>
                    Mesaj
                  </label>
                  <div className={`form-item-group-block`}>
                    <textarea
                      name=''
                      id=''
                      rows={4}
                      className={`form-item-control ${error?.message ? 'error' : ''}`}
                      value={formData.message}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setFormData({
                          ...formData,
                          message: e.target.value,
                        })
                      }
                    ></textarea>
                    {error?.message && (
                      <p className='error-message'>
                        {showError(error.message)}
                      </p>
                    )}
                  </div>
                </div>
                <div className='btns-container'>
                  <button
                    className='button-blue button-md'
                    onClick={() => submitForm()}
                  >
                    Trimite
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuggestModal;
