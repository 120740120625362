interface Props {}

const IconRingBell: React.FC<Props> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_3935_9994)'>
        <path
          d='M22.8272 17.9303C22.6956 16.56 21.4724 15.4838 20.0478 15.4838C19.8775 15.4838 19.742 15.3483 19.742 15.178V10.618C19.742 7.23091 17.5742 4.27738 14.5897 3.17416C14.6245 2.99994 14.6439 2.82191 14.6439 2.64382C14.6439 1.18454 13.4594 0 12 0C10.5406 0 9.35616 1.18454 9.35616 2.64388C9.35616 2.8181 9.37548 2.99225 9.40645 3.15871C8.38068 3.52259 7.43228 4.09935 6.62326 4.88128C5.09811 6.35226 4.25812 8.33031 4.25812 10.4516V15.1781C4.25812 15.3445 4.12263 15.4839 3.95229 15.4839C2.52391 15.4839 1.30453 16.56 1.17291 17.9304C1.09935 18.693 1.35101 19.4516 1.86583 20.0168C2.37678 20.5781 3.10842 20.9033 3.87097 20.9033H8.53938C8.73292 22.6414 10.2117 24.0001 12 24.0001C13.7884 24.0001 15.2672 22.6414 15.4607 20.9033H20.1291C20.8917 20.9033 21.6233 20.5781 22.1342 20.0168C22.6452 19.4516 22.9007 18.6929 22.8272 17.9303ZM12 1.54836C12.6039 1.54836 13.0955 2.03998 13.0955 2.64382C13.0955 2.69411 13.0877 2.74059 13.08 2.79088C12.8168 2.75216 12.5496 2.72508 12.2825 2.71732C11.8219 2.7018 11.369 2.73283 10.92 2.79476C10.9122 2.74447 10.9044 2.69799 10.9044 2.6477C10.9046 2.03998 11.3962 1.54836 12 1.54836ZM12 22.4516C11.0672 22.4516 10.2852 21.7857 10.1032 20.9032H13.8968C13.7149 21.7858 12.933 22.4516 12 22.4516ZM20.9884 18.9755C20.7639 19.2194 20.462 19.3548 20.129 19.3548H3.87103C3.53811 19.3548 3.23622 19.2193 3.01165 18.9755C2.78714 18.7316 2.68261 18.4103 2.71745 18.0774C2.77163 17.4929 3.31748 17.0323 3.95617 17.0323C4.97813 17.0323 5.81036 16.2 5.81036 15.1781V10.4516C5.81036 8.75612 6.48388 7.17292 7.70327 5.99614C8.86454 4.86971 10.382 4.25805 12 4.25805C12.0775 4.25805 12.151 4.25805 12.2284 4.26193C15.5149 4.37803 18.1936 7.23097 18.1936 10.6181V15.1781C18.1936 16.2 19.0258 17.0323 20.0478 17.0323C20.6865 17.0323 21.2323 17.4929 21.2865 18.0774C21.3175 18.4103 21.2129 18.7277 20.9884 18.9755Z'
          fill='black'
        />
        <path
          d='M1.97421 9.29034C2.33034 9.29034 2.65162 9.04263 2.72906 8.68262C3.10451 6.98326 3.95226 5.4349 5.19097 4.20394C5.49292 3.90199 5.49292 3.41037 5.19097 3.10848C4.88902 2.80653 4.3974 2.80653 4.09551 3.10848C2.6478 4.54837 1.65293 6.3639 1.21555 8.35352C1.12265 8.77157 1.38588 9.18575 1.80394 9.27483C1.86587 9.28647 1.92004 9.29034 1.97421 9.29034Z'
          fill='black'
        />
        <path
          d='M22.0258 9.29035C22.08 9.29035 22.1381 9.28259 22.1923 9.27102C22.6103 9.17813 22.8736 8.76777 22.7807 8.34971C22.3432 6.36003 21.3484 4.54844 19.9006 3.10455C19.5987 2.80259 19.1071 2.80259 18.8052 3.10455C18.5033 3.4065 18.5032 3.89812 18.8052 4.20001C20.04 5.43096 20.8916 6.97938 21.2671 8.67869C21.3484 9.04257 21.6697 9.29035 22.0258 9.29035Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_3935_9994'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconRingBell;
