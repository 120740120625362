import React from 'react';
import './FilterListHeaderPriceEvaluation.scss';

interface Props {
  sortItems?: (field: string) => void;
  sort?: string;
}

const FilterListHeaderPriceEvaluation: React.FC<Props> = ({
  sort,
  sortItems,
}) => {
  return (
    <div className='filter-list-container filter-header-price-evaluation-list-item'>
      <form>
        <div
          className={`filter-item arrows-label-icon ${sort === 'id' ? 'active-bottom' : ''} ${sort === '-id' ? 'active-top' : ''}`}
        >
          <label
            onClick={() => {
              if (sortItems)
                sort === 'id'
                  ? sortItems('-id')
                  : sort === '-id'
                    ? sortItems('')
                    : sortItems('id');
            }}
          >
            ID
          </label>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'dateCreated' ? 'active-bottom' : ''} ${sort === '-dateCreated' ? 'active-top' : ''}`}
        >
          <label
            onClick={() => {
              if (sortItems)
                sort === 'dateCreated'
                  ? sortItems('-dateCreated')
                  : sort === '-dateCreated'
                    ? sortItems('')
                    : sortItems('dateCreated');
            }}
          >
            Data adaugare
          </label>
        </div>
        <div className='filter-item'>
          <label>Filtre</label>
        </div>
        <div className='filter-item'>
          <label>Anunturi analizate</label>
        </div>
        <div className='filter-item'>
          <label>Pret recomandat</label>
        </div>
        <div className='filter-item'>
          <label>Actiuni</label>
        </div>
      </form>
    </div>
  );
};
export default FilterListHeaderPriceEvaluation;
