import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconRingBell from '../../icons/IconRingBell';
import './SearchAddModal.scss';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  submitForm: (popupNotification: number, emailNotification: number) => void;
}
const SearchAddModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  submitForm,
}) => {
  const [methods, setMethods] = useState<number[]>([]);

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={onCloseModal}
        animation={true}
        className={'modal announce-search-modal'}
      >
        <Modal.Header>
          <div className='close-btn-modal' onClick={onCloseModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          <span>
            <IconRingBell /> Metode de abonare
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className='announce-search-modal__content'>
            <div className='reports-cards-container display-flex'>
              <div
                className={`notification-item ${methods.includes(0) ? 'active' : ''}`}
                onClick={() =>
                  setMethods(prev =>
                    prev.includes(0)
                      ? prev.filter(id => id !== 0)
                      : [...prev, 0],
                  )
                }
              >
                <p>Email</p>
              </div>
              <div
                className={`notification-item ${methods.includes(1) ? 'active' : ''}`}
                onClick={() =>
                  setMethods(prev =>
                    prev.includes(1)
                      ? prev.filter(id => id !== 1)
                      : [...prev, 1],
                  )
                }
              >
                <p>Popup</p>
              </div>
            </div>
            <div className='btns-container'>
              <button
                className='button-blue button-md'
                onClick={() =>
                  submitForm(
                    methods.includes(0) ? 1 : 0,
                    methods.includes(1) ? 1 : 0,
                  )
                }
                disabled={methods.length === 0}
              >
                Aboneaza-te
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchAddModal;
