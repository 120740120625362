interface Props {
  className?: string;
}

const IconArrowRadius: React.FC<Props> = props => {
  return (
    <svg
      {...props}
      width='10'
      height='6'
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.2 0.200001C8.39257 0.055572 8.63078 -0.0145469 8.87089 0.00251712C9.111 0.0195812 9.3369 0.122682 9.50711 0.292894C9.67732 0.463105 9.78042 0.689002 9.79749 0.929112C9.81455 1.16922 9.74443 1.40743 9.6 1.6L5.6 5.6C5.41307 5.78323 5.16175 5.88586 4.9 5.88586C4.63825 5.88586 4.38693 5.78323 4.2 5.6L0.200002 1.6C0.0555725 1.40743 -0.014547 1.16922 0.00251711 0.929112C0.0195812 0.689002 0.122683 0.463105 0.292895 0.292894C0.463106 0.122682 0.689003 0.0195812 0.929112 0.00251712C1.16922 -0.0145469 1.40743 0.055572 1.6 0.200001L4.9 3.49L8.2 0.190001V0.200001Z'
        fill='white'
      />
    </svg>
  );
};

export default IconArrowRadius;
