import React, { useState } from 'react';
import DropdownBrands from '../../components/DropdownBrands';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import SlotAllocationTable from '../../components/SlotAllocationTable';
import SwiperDashboard from '../../components/SwiperDashboard';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './SlotAllocation.scss';

interface Props {}

const SlotAllocation: React.FC<Props> = () => {
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.AlocateSlot),
  );

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Alocare sloturi'
          subtitle='Lista Etichetelor adaugate in sectiunea de ImoAP'
          isbutton={false}
          btnText=''
        />
        {havePermission ? (
          <div className='display-flex flex-column slots-container'>
            <div className='slot-dropdown'><DropdownBrands /></div>
            <div className='display-flex flex-slot-allocation'>
              <SlotAllocationTable />
            </div>
          </div>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default SlotAllocation;
