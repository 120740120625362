import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getAgentsReports = createAsyncThunk<any, any>(
  'crm/getAgentsReports',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        'api/v1/crm/reports/agents-statistics',
        {
          params: data,
        },
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getEstateReports = createAsyncThunk<any, any>(
  'crm/getEstateReports',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        'api/v1/crm/reports/estates-demands',
        {
          params: data,
        },
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
