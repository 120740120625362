import moment from 'moment';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import ModalPDF from '../../components/ModalPDF';
import { getFilePath } from '../../helpers/useHelper';
import { Contract } from '../../models/Contract';
import Avatar from '../../assets/img/avatar.png';
import IconPDF from '../../icons/IconPDF';
import IconPencil from '../../icons/IconPencil';
import IconPencilWriting from '../../icons/IconPencilWriting';
import ModalSign from '../ModalSign';
import './ContractsListItem.scss';

interface Props {
  item: Contract;
  checked: boolean;
  handleCheckboxChange: (id: number) => void;
}

const ContractsListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
}) => {
  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);

  return (
    <div
      className={`announces-list-item item-contracts ${checked && 'active'}`}
    >
      <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={checked}
              onChange={() => handleCheckboxChange(item.id)}
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div>
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='mobile-text'>ID</div>
          <div className='info-nr label-item-mobile'>#{item.id}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Contract</div>
          <div className='info-title label-item-mobile'>
            <strong>{item.title}</strong>
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Data</div>
          <div className='info-date label-item-mobile'>
            {moment(item?.dateCreated).format('DD.MM.YYYY')}{' '}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Tip contract</div>
          <div className='info-date label-item-mobile'>
            <span className='label-item-tag'>
              {' '}
              Contract de{' '}
              {item.priceTypeTitle === 'SALE'
                ? 'vanzare'
                : item.priceTypeTitle === 'RENT_MONTH'
                  ? 'inchiriere'
                  : 'regim hotelier'}
            </span>
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Agent</div>

          <div className='info-agent'>
            <div className='info-image'>
              <img
                src={
                  item.employeeAvatarPath
                    ? getFilePath(item.employeeAvatarPath)
                    : Avatar
                }
                alt=''
              />
            </div>
            <div className='info-name'>{item.employeeProfileFullName}</div>
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Status</div>
          <div className='info-date label-item-mobile'>
            <span className='label-item-tag'>
              {item.signStatus === 'completed' ? 'Semnat' : 'In draft'}
            </span>
            {/* <span className='label-item-mark'>Semnat</span> */}
          </div>
        </div>

        <div className='line-border'></div>
        <div className='info-icons'>
          {item.signStatus !== 'completed' && (
            <button
              className='button-square-md'
              aria-label=''
              onClick={() => setShowSignModal(true)}
            >
              <IconPencilWriting />
            </button>
          )}
          <button
            className='button-square-md'
            aria-label=''
            onClick={() => {
              navigate('/edit-contract/' + item.id);
            }}
          >
            <IconPencil />
          </button>

          <button
            className='button-square-md'
            onClick={() => setShowConfirmModal(true)}
            aria-label=''
          >
            <IconPDF />
          </button>
          {showConfirmModal && (
            <ModalPDF
              showModal={showConfirmModal}
              item={item}
              hideModal={() => setShowConfirmModal(false)}
            />
          )}
          {showSignModal && (
            <ModalSign
              showModal={showSignModal}
              id={item.id}
              hideModal={() => setShowSignModal(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractsListItem;
