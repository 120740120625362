interface Props {}

const IconArrowDownSmall: React.FC<Props> = props => {
  return (
    <svg
      {...props}
      width='11'
      height='7'
      viewBox='0 0 11 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.20408 0.224491C9.42023 0.0623768 9.68761 -0.0163282 9.95712 0.00282534C10.2266 0.0219789 10.4802 0.137705 10.6712 0.328758C10.8623 0.519812 10.978 0.77337 10.9972 1.04288C11.0163 1.31239 10.9376 1.57977 10.7755 1.79592L6.28571 6.28571C6.0759 6.49138 5.7938 6.60657 5.5 6.60657C5.2062 6.60657 4.9241 6.49138 4.71429 6.28571L0.224492 1.79592C0.0623773 1.57977 -0.0163282 1.31239 0.00282532 1.04288C0.0219789 0.77337 0.137706 0.519812 0.328759 0.328758C0.519813 0.137705 0.77337 0.0219789 1.04288 0.00282534C1.31239 -0.0163282 1.57977 0.0623768 1.79592 0.224491L5.5 3.91735L9.20408 0.213266V0.224491Z'
        fill='#484848'
      />
    </svg>
  );
};

export default IconArrowDownSmall;
