import moment from 'moment';
import Table from 'react-bootstrap/Table';
import { ReportRow } from '../../models/Report';
import './ReportsEstateTable.scss';

interface Props {
  rows: ReportRow[];
}

const ReportsEstateTable: React.FC<Props> = ({ rows }) => {
  return (
    <div className='estate-table-container'>
      <div className='table-item'>
        <Table striped hover>
          <thead>
            <tr>
              <th>Perioada</th>
              <th>Proprietati noi </th>
              <th>Solicitari noi </th>
              <th>Contacte noi </th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row: ReportRow, index: number) => (
              <tr key={index}>
                <td>
                  <span>{moment(row.date).format('DD-MM-YYYY')}</span>
                </td>
                <td>
                  <span>{row.propertiesCount}</span>
                </td>
                <td>
                  <span>{row.requestsCount}</span>
                </td>
                <td>
                  <span>{row.contactsCount}</span>
                </td>
              </tr>
            ))}
            <tr>
              <th>Total</th>
              <th>{rows.reduce((sum, row) => sum + row.propertiesCount, 0)}</th>
              <th>{rows.reduce((sum, row) => sum + row.requestsCount, 0)}</th>
              <th>{rows.reduce((sum, row) => sum + row.contactsCount, 0)}</th>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ReportsEstateTable;
