import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getContractTemplateFields } from '../../redux/contractTemplates/contractTemplatesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ContractTemplateField } from '../../models/ContractTemplates';
import IconCopy from '../../icons/IconCopy';
import IconTimes from '../../icons/IconTimes';
import Input from '../Input';
import './AddContractTemplateFormContact.scss';

interface Props {
  fields: ContractTemplateField[];
  setFields: (e: ContractTemplateField[]) => void;
}

const slugify = (text: string) => {
  let name = '';
  if (text) {
    name = text.toLowerCase();
    name = name.replace(/[^\w\s]/g, '');

    return '{{' + name.replace(/\s+/g, '_') + '}}';
  } else return '';
};

const AddContractTemplateFormContact: React.FC<Props> = ({
  fields,
  setFields,
}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [additionalFields, setAdditionalFields] = useState<
    ContractTemplateField[]
  >(fields ?? []);

  const { contractTemplateFields, contractTemplate } = useAppSelector(
    state => state.contractTemplates,
  );

  useEffect(() => {
    dispatch(getContractTemplateFields());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    contractTemplate?.fields &&
      id &&
      contractTemplate?.fields?.length > 0 &&
      setAdditionalFields(contractTemplate?.fields);
    // eslint-disable-next-line
  }, [contractTemplate]);

  useEffect(() => {
    if (JSON.stringify(fields) !== JSON.stringify(additionalFields))
      setFields(additionalFields);
    // eslint-disable-next-line
  }, [additionalFields]);

  const removeAdditional = (index: number) => {
    setAdditionalFields([
      ...additionalFields.filter(
        (field: ContractTemplateField, id: number) => id !== index,
      ),
    ]);
  };

  const addAdditional = () => {
    setAdditionalFields([
      ...additionalFields,
      {
        title: '',
        slug: '',
        initialValue: '',
      },
    ]);
  };

  const handleChangeAdditional = (key: string, e: string, index: number) => {
    const newArray = additionalFields.map(
      (field: ContractTemplateField, id: number) => {
        if (id === index)
          return {
            ...field,
            [key]: e,
            ...(key === 'title' ? { slug: slugify(e) } : {}),
          };
        else return field;
      },
    );
    setAdditionalFields(newArray);
  };

  const filterByGroup = (array: any, additional = false) => {
    let groups: any[] = additional ? ['Aditionale'] : [];

    if (!additional) {
      array.forEach((field: any) => {
        if (!groups?.includes(field.group)) groups.push(field.group);
      });
    }

    return groups?.map((group: any, index) => {
      return (
        <Fragment key={index}>
          <p className='fieldset-heading'>{group}</p>

          {array
            .filter((field: ContractTemplateField) =>
              additional ? field : field.group === group,
            )
            .map((item: ContractTemplateField) => (
              <div className='contract-info-list__item'>
                <div className='item-start'>
                  {' '}
                  <div className='item-th'>
                    {item.title}{' '}
                    {item.initialValue && (
                      <span className='label-item-tag'>
                        {item.initialValue}
                      </span>
                    )}
                  </div>
                  <div className='item-td'>
                    <span className='label-item-tag'>{item.slug}</span>
                  </div>
                </div>
                <button
                  className='button-square-sm'
                  onClick={e => {
                    e.preventDefault();
                    navigator.clipboard.writeText(item.slug);
                  }}
                >
                  <IconCopy />
                </button>
              </div>
            ))}
        </Fragment>
      );
    });
  };

  return (
    <>
      <div className='add-contract-wrapper add-contract-form-contact-wrapper bg-white'>
        <form action=''>
          <p className='fieldset-heading'>Adauga campuri aditionale</p>
          <div className='form-field'>
            {additionalFields?.map(
              (additional: ContractTemplateField, index: number) => (
                <div className='form-items-group'>
                  <div className='form-item-lg'>
                    <Input
                      labelText={true}
                      inlineLabel='Denumire'
                      value={additional.title}
                      onChange={e => {
                        handleChangeAdditional('title', e.target.value, index);
                      }}
                    />
                  </div>
                  <div className='form-item-lg'>
                    <Input
                      labelText={true}
                      inlineLabel='Valoare initiala'
                      value={additional.initialValue}
                      onChange={e =>
                        handleChangeAdditional(
                          'initialValue',
                          e.target.value,
                          index,
                        )
                      }
                    />
                  </div>
                  <div className='form-item-sm'>
                    <button
                      className='button-square-lg'
                      onClick={e => {
                        e.preventDefault();
                        removeAdditional(index);
                      }}
                    >
                      <IconTimes />
                    </button>
                  </div>
                </div>
              ),
            )}

            <button
              className='add-block'
              onClick={e => {
                e.preventDefault();
                addAdditional();
              }}
            >
              <span>+ Adauga camp nou</span>
            </button>
          </div>
        </form>
        <div className='contract-info-field'>
          <p className='fieldset-heading'>Pentru utilizare in contract</p>
          <div className='contract-info-list'>
            {filterByGroup(contractTemplateFields)}
            {fields.filter(field => field.slug.length > 0).length > 0
              ? filterByGroup(fields, true)
              : null}

            <p className='fieldset-heading'>Semnaturi</p>

            <div className='contract-info-list__item'>
              <div className='item-start'>
                <div className='item-th'>Semnatura agent</div>
                <div className='item-td'>
                  <span className='label-item-tag'>/sn1/</span>
                </div>
              </div>
              <button
                className='button-square-sm'
                onClick={e => {
                  e.preventDefault();
                  navigator.clipboard.writeText('/sn1/');
                }}
              >
                <IconCopy />
              </button>
            </div>
            <div className='contract-info-list__item'>
              <div className='item-start'>
                <div className='item-th'>Semnatura client</div>
                <div className='item-td'>
                  <span className='label-item-tag'>/sn2/</span>
                </div>
              </div>
              <button
                className='button-square-sm'
                onClick={e => {
                  e.preventDefault();
                  navigator.clipboard.writeText('/sn2/');
                }}
              >
                <IconCopy />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContractTemplateFormContact;
