import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';
import { UsersResponse } from '../users/usersThunk';

export const getActiveAgents = createAsyncThunk<UsersResponse>(
  'crm/getActiveAgents',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        limit: -1,
        status: '1',
        isDashboard: 1
      };
      const response = await appAxios.get('api/v1/crm/users', { params });
      const totalCount = response.headers['x-total-count'];

      return { users: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getLastAnnouncesCount = createAsyncThunk<any>(
  'crm/getLastAnnouncesCount',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        limit: 0,
        isDashboard: 1,
        'filters[31][from]': moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
        'filters[31][to]': moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      const response = await appAxios.get('api/v1/crm/announcements', { params });

      return response.headers['x-total-count'];
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getLastRequestsCount = createAsyncThunk<any>(
  'crm/getLastRequestsCount',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        limit: 0,
        isDashboard: 1,
        status: '1,4,0,8',
        'filters[106][from]': moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
        'filters[106][to]': moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      const response = await appAxios.get('api/v1/crm/requests', { params });

      return response.headers['x-total-count'];
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
