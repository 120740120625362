import { createSlice } from '@reduxjs/toolkit';
import { Filter } from '../../models/Announces';
import { Evaluation, EvaluationListItem } from '../../models/Evaluation';
import {
  createEvaluation,
  getAnnouncementsEvaluationByFilters,
  getEvaluation,
  getEvaluationFilters,
  getEvaluations,
  getPricesTypes,
} from './evaluationsThunk';

type EvaluationsState = {
  filters: Filter[];
  pricesTypes: any[];
  evaluation: Evaluation | null;
  evaluations: EvaluationListItem[];
  totalCount: number;
  loadingFilters: boolean;
  loadingFilterEvaluation: boolean;
  loading: boolean;
  error: any;
  errorFilters: any;
};

const initialState: EvaluationsState = {
  filters: [],
  pricesTypes: [],
  loadingFilters: false,
  evaluation: null,
  evaluations: [],
  totalCount: 0,
  loading: false,
  loadingFilterEvaluation: false,
  error: null,
  errorFilters: null,
};

const evaluationsSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getEvaluationFilters.pending, state => {
        state.loadingFilters = true;
        state.errorFilters = null;
      })
      .addCase(getEvaluationFilters.fulfilled, (state, { payload }) => {
        state.filters = payload;
        state.errorFilters = null;
        state.loadingFilters = false;
      })
      .addCase(getEvaluationFilters.rejected, (state, action) => {
        state.loadingFilters = false;
        state.errorFilters = action.payload;
      })
      .addCase(getPricesTypes.pending, state => {
        state.error = null;
      })
      .addCase(getPricesTypes.fulfilled, (state, { payload }) => {
        state.pricesTypes = payload;
        state.error = null;
      })
      .addCase(getPricesTypes.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getAnnouncementsEvaluationByFilters.pending, (state, action) => {
        state.error = null;
        state.loadingFilterEvaluation = true;
      })
      .addCase(
        getAnnouncementsEvaluationByFilters.fulfilled,
        (state, { payload }) => {
          state.evaluation = payload;
          state.loadingFilterEvaluation = false;
        },
      )
      .addCase(
        getAnnouncementsEvaluationByFilters.rejected,
        (state, action) => {
          state.evaluation = null;
          state.error = action.payload;
          state.loadingFilterEvaluation = false;
        },
      )
      .addCase(getEvaluation.pending, (state, action) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getEvaluation.fulfilled, (state, { payload }) => {
        state.evaluation = payload;
        state.loading = false;
      })
      .addCase(getEvaluation.rejected, (state, action) => {
        state.evaluation = null;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createEvaluation.fulfilled, (state, action) => {
        state.error = false;
      })
      .addCase(createEvaluation.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getEvaluations.pending, (state, action) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getEvaluations.fulfilled, (state, { payload }) => {
        state.error = false;
        state.evaluations = payload.evaluations;
        state.totalCount = payload.totalCount;
        state.loading = false;
      })
      .addCase(getEvaluations.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
  initialState,
  name: 'evaluations',
  reducers: {
    clearEvaluation(state) {
      state.error = null;
      state.evaluation = null;
    },
  },
});

export const { clearEvaluation } = evaluationsSlice.actions;

export default evaluationsSlice.reducer;
