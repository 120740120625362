import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import ModalPreviewTemplate from '../../components/ModalPreviewTemplate';
import { ContractTemplateListItem } from '../../models/ContractTemplates';
import IconPDF from '../../icons/IconPDF';
import IconPencil from '../../icons/IconPencil';
import IconTrash from '../../icons/IconTrash';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import './ContractsTemplatesListItem.scss';

interface Props {
  template: ContractTemplateListItem;
  onRemoveTemplate: any;
}

const ContractsTemplatesListItem: React.FC<Props> = ({
  template,
  onRemoveTemplate,
}) => {
  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className={`announces-list-item item-contracts-templates`}>
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='mobile-text'>ID</div>
          <div className='info-nr label-item-mobile'>{template.id}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Data</div>
          <div className='info-date label-item-mobile'>
            {template.dateCreated}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Contract</div>
          <div className='info-date label-item-mobile'>
            <p>{template.title}</p>
          </div>
        </div>

        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            onClick={() => setShowConfirmModal(true)}
            data-tooltip-id='pdf'
            data-tooltip-content='PDF'
          >
            <IconPDF />
          </button>
          <Tooltip id='pdf' />
          {showConfirmModal && (
            <ModalPreviewTemplate
              showModal={showConfirmModal}
              item={template}
              hideModal={() => setShowConfirmModal(false)}
            />
          )}

          <button
            className='button-square-md'
            aria-label=''
            onClick={() => {
              navigate('/edit-contract-template/' + template.id);
            }}
          >
            <IconPencil />
          </button>
          <button
            className='button-square-md'
            data-tooltip-id='remove'
            data-tooltip-content='Sterge'
            aria-label='Sterge'
            onClick={e => {
              e.preventDefault();
              setShowDeleteModal(true);
            }}
          >
            <IconTrash />
          </button>
          <Tooltip id='remove' />
        </div>
      </div>
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere template'
        body='Esti sigur ca doresti sa stergi template-ul?'
        onConfirmed={onRemoveTemplate}
        hideModal={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default ContractsTemplatesListItem;
