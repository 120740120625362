import moment from 'moment';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Form, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { ReportEstateItem, ReportRow } from '../../models/Report';
import './ReportsEstateChart.scss';

interface Props {
  row: ReportEstateItem;
}
const ReportsEstateChart: React.FC<Props> = ({ row }) => {
  const [data, setData] = useState<any>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [visibleLines, setVisibleLines] = useState({
    propertiesCount: true,
    requestsCount: true,
    contactsCount: true,
  });

  useEffect(() => {
    // Initialize arrays to store dates and counts for each line
    const dateList: string[] = [];
    const propertiesData: number[] = [];
    const requestsData: number[] = [];
    const contactsData: number[] = [];

    // Loop through the rows and organize the data by date
    row.rows.forEach((report: ReportRow) => {
      const dateFormatted = moment(report.date).format('DD-MM-YYYY');

      // Add the date to the dateList if it isn't already included
      if (!dateList.includes(dateFormatted)) {
        dateList.push(dateFormatted);
      }

      // Initialize arrays for each count type by date
      propertiesData.push(report.propertiesCount);
      requestsData.push(report.requestsCount);
      contactsData.push(report.contactsCount);
    });

    // Set the dates and the data for the series
    setDates(dateList);
    setData([
      {
        name: 'Properietati noi',
        data: propertiesData,
        visible: visibleLines.propertiesCount,
      },
      {
        name: 'Solicitari noi',
        data: requestsData,
        visible: visibleLines.requestsCount,
      },
      {
        name: 'Contacte noi',
        data: contactsData,
        visible: visibleLines.contactsCount,
      },
    ]);
  }, [row, visibleLines]);

  // Toggle visibility for each line
  const toggleLineVisibility = (line: string) => {
    setVisibleLines(prevState => ({
      ...prevState,
      [line]: !prevState[line as keyof typeof prevState], // Toggle the visibility
    }));
  };

  // Update the series based on visibility
  const updatedSeries = data.filter((series: any) => series.visible);

  const graph = {
    options: {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
        dataLabels: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        stroke: {
          width: [4, 4, 4], // Define width for each line
        },
        legend: {
          offsetX: 40,
        },
      },
      colors: ['#33475b', '#ff6f61', '#4caf50'], // Colors for each line
      plotOptions: {
        bar: {
          columnWidth: '20%',
        },
      },
      xaxis: {
        categories: dates, // Use the dates as categories
      },
      yaxis: [
        {
          axisBorder: {
            show: true,
            color: '#33475b',
          },
          labels: {
            style: {
              colors: '#33475b',
            },
          },
          title: {
            text: 'Count',
            style: {
              color: '#33475b',
            },
          },
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: false,
        x: {
          show: false,
        },
      },
    },
    series: updatedSeries, // Only show visible series
  };

  return (
    <div className='estate-chart-container'>
      <Row className='grafic-container'>
        <Col lg='12'>
          <div className='grafic-block'>
            <Chart
              options={graph.options}
              series={graph.series}
              type='line'
              width='100%'
            />
          </div>

          <div className='checkbox-container'>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                label='Proprietati Noi'
                checked={visibleLines.propertiesCount}
                onChange={() => toggleLineVisibility('propertiesCount')}
              />
            </Form.Group>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                label='Solicitari Noi'
                checked={visibleLines.requestsCount}
                onChange={() => toggleLineVisibility('requestsCount')}
              />
            </Form.Group>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                label='Contacte Noi'
                checked={visibleLines.contactsCount}
                onChange={() => toggleLineVisibility('contactsCount')}
              />
            </Form.Group>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ReportsEstateChart;
