import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormSelect from '../../components/FormSelect';
import Header from '../../components/Header';
import Input from '../../components/Input/Input';
import Map from '../../components/Map';
import MapChoice from '../../components/MapChoice';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import StreetAddModal from '../../components/StreetAddModal';
import { getCategories } from '../../redux/categories/categoriesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearStreets,
  clearZona,
} from '../../redux/localities/localities-slice';
import {
  getCoordinates,
  getCounty,
  getLocalities,
  getResidentialComplexes,
  getStreet,
  getZona,
} from '../../redux/localities/localitiesThunk';
import { createProperty } from '../../redux/properties/propertiesThunk';
import useHelper, {
  getNumber,
  haveUserPermission,
  parseValues,
  showError,
} from '../../helpers/useHelper';
import { Category } from '../../models/Categories';
import { Permissions } from '../../models/Permissions';
import {
  Coordinate,
  PropertySubmit,
  SelectValue,
} from '../../models/Properties';
import './Property.scss';

interface FormData {
  category?: SelectValue;
  town?: SelectValue;
  county?: SelectValue;
  zona?: SelectValue;
  street?: SelectValue;
  streetNumber?: string;
  block?: string;
  staircase?: string;
  floorNumber?: string;
  field?: string;
  parcel?: string;
  lot?: string;
  residentialComplex?: SelectValue;
}

const Property: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();
  const { error, loadingSave } = useAppSelector(state => state.properties);
  const { categories } = useAppSelector(state => state.categories);
  const { localities, county, streets, zona, residentialComplexes } =
    useAppSelector(state => state.localities);

  const [formData, setFormData] = useState<FormData>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState<Category>();
  const [coordinates, setCoordinates] = useState<Coordinate>({
    type: 'PIN',
    lat: 44.26,
    lng: 26.6,
  });
   // eslint-disable-next-line
   const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.PropertyAdd),
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    categories.length === 0 && dispatch(getCategories());
    dispatch(getCounty({ parent: 0 }));
    dispatch(getResidentialComplexes({}));

    let initialForm = {
      category: undefined,
      town: undefined,
      county: undefined,
      street: undefined,
      zona: undefined,
    };

    setFormData(initialForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formData.category &&
      setActiveCategory(
        categories.find(
          category => String(category.id) === formData.category?.value,
        ),
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.category]);

  useEffect(() => {
    activeCategory?.additionalFields?.includes('residentialComplex') &&
      dispatch(getResidentialComplexes({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    if (formData.county)
      dispatch(getLocalities({ parent: formData.county.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.county]);

  useEffect(() => {
    if (formData.town) {
      dispatch(getStreet({ town: formData.town.value }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.town]);

  useEffect(() => {
    if (formData.street) {
      dispatch(getZona({ street: formData.street.value }));
    }

    getCoords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.street]);

  const getCoords = () => {
    if (formData?.county?.value && formData?.town?.value && formData?.street?.value) {
      dispatch(
          getCoordinates({
            county: formData.county.value,
            town: formData.town.value,
            street: formData.street.value,
          }),
      ).then(res => {
        if (
            res.type === 'localities/getCoordinates/fulfilled' &&
            res.payload.lat
        ) {
          setCoordinates({
            lat: res.payload.lat,
            lng: res.payload.lng,
            type: 'PIN',
          });
        }
      });
    }
  };

  const handleChange = (key: string, e: SelectValue) => {
    setFormData({ ...formData, [key]: e });
  };

  const handleInput = (key: string, e: string) => {
    setFormData({ ...formData, [key]: e });
  };

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    let parseForm: PropertySubmit = {
      categoryId: getNumber(formData.category?.value),
      countyId: getNumber(formData.county?.value),
      townId: getNumber(formData.town?.value),
      streetId: getNumber(formData.street?.value),
      zoneId: getNumber(formData.zona?.value),
      streetNumber: formData?.streetNumber ?? null,
      block: formData?.block ?? null,
      staircase: formData?.staircase ?? null,
      floorNumber: formData?.floorNumber ?? null,
      parcel: formData?.parcel ?? null,
      lot: formData?.lot ?? null,
      field: formData?.field ?? null,
      residentialComplexId: getNumber(formData?.residentialComplex?.value),
      coordinates: {
        type: coordinates.type,
        lat: coordinates.lat,
        lng: coordinates.lng,
      },
    };

    dispatch(createProperty(parseForm)).then(res => {
      if (res.payload.id) {
        navigate('/properties/' + res.payload.id);
      } else diplayErrorMessages(res.payload);
    });
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Adauga proprietate'
          subtitle=''
          isbutton={false}
          backBtn={true}
          btnText=''
        />
        {havePermission ? (
          <>
            <div className='section-container'>
              <div className='bg-white property-left'>
                <h2 className='heading-block'>Localizare</h2>
                <form action='' className='form-block'>
                  <div className='form-item'>
                    <FormSelect
                      labelText={true}
                      placeholder='Select Options'
                      inlineLabel='Tip Proprietate:'
                      isSearchable={true}
                      className={error?.categoryId ? 'error' : ''}
                      value={formData?.category}
                      options={parseValues(categories) ?? []}
                      onChange={(e: SelectValue) => handleChange('category', e)}
                    />
                    {error?.categoryId && (
                      <p className='error-message'>
                        {showError(error.categoryId)}
                      </p>
                    )}
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Judet'
                      value={formData?.county ?? null}
                      options={parseValues(county) ?? []}
                      onChange={(e: SelectValue) => {
                        handleChange('county', e)
                        dispatch(clearStreets());
                        dispatch(clearZona());
                      }}
                    />
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Localitate'
                      value={formData?.town ?? null}
                      options={parseValues(localities) ?? []}
                      onChange={(e: SelectValue) => {
                        handleChange('town', e)
                        dispatch(clearZona());
                      }}
                    />
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      labelText={true}
                      inlineLabel='Strada'
                      isClearable={true}
                      options={parseValues(streets) ?? []}
                      onChange={(e: SelectValue) => handleChange('street', e)}
                      value={formData.street ?? null}
                    />
                    <p className='link' onClick={() => setIsModalOpen(true)}>
                      Propune Strada/Zona
                    </p>
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Zona'
                      value={formData?.zona ?? null}
                      options={parseValues(zona) ?? []}
                      onChange={(e: SelectValue) => handleChange('zona', e)}
                    />
                  </div>
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.streetNumber}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInput('streetNumber', e.target.value)
                      }
                      inlineLabel='Nr. Strada'
                    />
                  </div>
                  {activeCategory?.additionalFields?.includes('block') && (
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        value={formData?.block}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInput('block', e.target.value)
                        }
                        inlineLabel='Block'
                      />
                    </div>
                  )}
                  {activeCategory?.additionalFields?.includes('staircase') && (
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        value={formData?.staircase}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInput('staircase', e.target.value)
                        }
                        inlineLabel='Scara'
                      />
                    </div>
                  )}
                  {activeCategory?.additionalFields?.includes(
                    'floorNumber',
                  ) && (
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        value={formData?.floorNumber}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInput('floorNumber', e.target.value)
                        }
                        inlineLabel='Apartament'
                      />
                    </div>
                  )}
                  {activeCategory?.additionalFields?.includes('field') && (
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        value={formData?.field}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInput('field', e.target.value)
                        }
                        inlineLabel='Tarla'
                      />
                    </div>
                  )}
                  {activeCategory?.additionalFields?.includes('parcel') && (
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        value={formData?.parcel}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInput('parcel', e.target.value)
                        }
                        inlineLabel='Parcela'
                      />
                    </div>
                  )}
                  {activeCategory?.additionalFields?.includes('lot') && (
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        value={formData?.lot}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInput('lot', e.target.value)
                        }
                        inlineLabel='Lot'
                      />
                    </div>
                  )}
                  {activeCategory?.additionalFields?.includes(
                    'residentialComplex',
                  ) && (
                    <div className='form-item'>
                      <FormSelect
                        placeholder='Select Options'
                        labelText={true}
                        isClearable={true}
                        inlineLabel='Ansamblu rezidential'
                        value={formData?.residentialComplex}
                        options={parseValues(residentialComplexes) ?? []}
                        onChange={(e: SelectValue) =>
                          handleChange('residentialComplex', e)
                        }
                      />
                    </div>
                  )}
                </form>
              </div>
              <div className='bg-blue property-right '>
                <MapChoice
                  coordinates={coordinates}
                  setCoordinates={setCoordinates}
                />
                <div className='map'>
                  <Map
                    coordinates={coordinates}
                    setCoordinates={setCoordinates}
                  />
                </div>
              </div>
              <div className='form-buttons bg-white'>
                <button
                  className='button-blue button-lg button-spin'
                  onClick={(e: React.FormEvent) => submitForm(e)}
                >
                  {loadingSave ? (
                    <Spinner className='btn-blue-spinner' />
                  ) : null}
                  Adauga
                </button>
                <button
                  className='button-border button-back button-lg'
                  onClick={() => navigate(-1)}
                >
                  Inapoi
                </button>
              </div>
            </div>
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <StreetAddModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Property;
