import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '../../assets/svg/closemodal.svg';
import ActionsMobileContract from '../ActionsMobileContract';
import FilterListContracts from '../FilterListContracts/FilterListContracts';
import SearchMobile from '../SearchMobile';
import './ContractModal.scss';

interface Props {
  isModalOpen: boolean;
  isAction: boolean;
  isSearch: boolean;
  isFilter: boolean;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  closeModal: () => void;
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  editItems?: () => void;
}

const ContractModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  isAction,
  isSearch,
  isFilter,
  filtersForm,
  setFiltersForm,
  isCheckedAll,
  setCheckedAll,
  editItems,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={
          isFilter || isAction ? 'mobile-modal filter-overflow' : 'mobile-modal'
        }
      >
        <Modal.Body>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          {isSearch && <SearchMobile />}
          {isAction && <ActionsMobileContract editItems={editItems} />}
          {isFilter && (
            <FilterListContracts
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              onSubmit={closeModal}
              isCheckedAll={isCheckedAll}
              setCheckedAll={setCheckedAll}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContractModal;
