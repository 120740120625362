import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../redux/auth/authThunk';
import { useAppDispatch } from '../../redux/hooks';
import LogoAdai from '../../assets/svg/ADAI.svg';
import ArrowDown from '../../assets/svg/arrowDown.svg';
import closeicon from '../../assets/svg/closewhite.svg';
import Logo from '../../assets/svg/logo.svg';
import burgerIcon from '../../assets/svg/mobilenavbtn.svg';
import IconArrowLeft from '../../icons/IconArrowLeft';
import IconBell from '../../icons/IconBell';
import IconCalendar from '../../icons/IconCalendar';
import IconDetails from '../../icons/IconDetails';
import IconHeadPhone from '../../icons/IconHeadPhone';
import IconLogOut from '../../icons/IconLogOut';
import IconSearch from '../../icons/IconSearch';
import IconUser from '../../icons/IconUser';
import NotificationDropdown from '../NotificationDropdown/NotificationDropdown';
import navData from '../SideNav/accordion.json';
import './Header.scss';

interface Props {
  title: string;
  subtitle: string;
  isbutton: boolean;
  btnText: string;
  btnEvent?: () => void;
  backBtn?: boolean;
  breadcrumbs?: any[];
}

interface SublinkProps {
  sublinks?: SublinkType[];
}

interface SublinkType {
  title: string;
  link: string;
}

const Sublinks: React.FC<SublinkProps> = ({ sublinks }) => {
  const location = useLocation();

  return (
    <div className='accordion-body'>
      <ul>
        {sublinks &&
          sublinks.map((sublink, index) => (
            <li key={index}>
              <Link
                className={`nav-item-sublink ${location.pathname === sublink.link ? 'active' : ''}`}
                to={sublink.link}
              >
                {sublink.title}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
interface AccordionItem {
  id: string;
  icon: string;
  text: string;
  link?: string;
  sublinks?: SublinkType[];
}

const Header: React.FC<Props> = ({
  title,
  subtitle,
  isbutton,
  btnText,
  btnEvent,
  backBtn,
  breadcrumbs,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const searchRef = useRef<any>(null);
  const location = useLocation();
  const [openItems, setOpenItems] = useState<string[]>([]);
  const [openNav, setOpenNav] = useState(() => {
    const savedState = localStorage.getItem('sideNavState');

    return savedState !== null && window.innerWidth > 1081
      ? JSON.parse(savedState)
      : false;
  });
  const [initialMenu, setInitialMenu] = useState({
    isNotification: false,
    isCalendar: false,
    isProfile: false,
    isNotificationMobile: false,
    isCalendarMobile: false,
    isProfileMobile: false,
  });
  const [menu, setMenu] = useState(initialMenu);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useEffect(() => {
    localStorage.setItem('sideNavState', JSON.stringify(openNav));
  }, [openNav]);

  const handleClickOutside = (event: any) => {
    if (event.target.classList.contains('header-container-mobile')) {
      setIsMenuOpened(false);
    }

    if (
      ref?.current &&
      (!ref.current?.contains(event.target) ||
        event.target.classList.contains('dropdown-modal-container') ||
        event.target.classList.contains('header-container-mobile'))
    ) {
      resetMenuDropdown();
    }

    if (searchRef?.current && !searchRef.current?.contains(event.target)) {
      setToggleSearch(false);
    }
  };

  const handleClickOutsideMenu = (event: any) => {
    if (event.target.classList.contains('header-menu')) {
      setOpenNav(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    setInitialMenu({
      isNotification: false,
      isCalendar: false,
      isProfile: false,
      isNotificationMobile: false,
      isCalendarMobile: false,
      isProfileMobile: false,
    });
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, []);

  const updateMenuDropdown = (name: string, value: boolean) => {
    setMenu(() => {
      return { ...initialMenu, [name]: value };
    });
  };

  const resetMenuDropdown = () => {
    setMenu(initialMenu);
  };

  const ModalSearch = () => {
    setToggleSearch(!toggleSearch);
  };

  const toggleDropdown = (itemId: string) => {
    setOpenItems(prevItems => {
      if (prevItems.includes(itemId)) {
        return [];
      } else {
        return [itemId];
      }
    });
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className='header-menu' ref={ref}>
      <div className='header-container display-flex'>
        {backBtn && (
          <div className='back-btn' onClick={() => navigate(-1)}>
            <IconArrowLeft />
          </div>
        )}
        <div className='header-title'>
          <h2>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
          {breadcrumbs && breadcrumbs?.length > 1 && (
            <div className='header-breadcrumbs'>
              {breadcrumbs?.map(breadcrumb => (
                <a href={breadcrumb?.link} key={breadcrumb.name + 'breadcrumb'}>
                  {breadcrumb?.name}
                </a>
              ))}
            </div>
          )}
        </div>

        <div className='right-section display-flex'>
          {isbutton && (
            <button
              onClick={() => btnEvent && btnEvent()}
              className='button-blue button-md'
            >
              {btnText}
            </button>
          )}
          <div className='search-container'>
            <input placeholder='Search' type='text' id='search-nav' />
            <IconSearch />
          </div>
          <div
            className={
              menu.isNotification || menu.isCalendar || menu.isProfile
                ? 'profile-data-container active'
                : 'profile-data-container'
            }
          >
            <div
              className={
                menu.isCalendar ? 'profile-item active-click' : 'profile-item'
              }
              onClick={() => updateMenuDropdown('isCalendar', !menu.isCalendar)}
            >
              <IconCalendar />
            </div>
            <div
              className={
                menu.isNotification
                  ? 'profile-item notification active active-click'
                  : 'profile-item notification active'
              }
              onClick={() =>
                updateMenuDropdown('isNotification', !menu.isNotification)
              }
            >
              <IconBell />
            </div>
            <div
              className={
                menu.isProfile
                  ? 'profile-item account active active-click'
                  : 'profile-item account active'
              }
              onClick={() => updateMenuDropdown('isProfile', !menu.isProfile)}
            >
              <div className='icon-container'>
                <IconUser />
              </div>
              <img src={ArrowDown} alt='Profile' />
            </div>
          </div>
          <NotificationDropdown
            isNotification={menu.isNotification}
            isCalendar={menu.isCalendar}
            isProfile={menu.isProfile}
            logout={logoutHandler}
          />
        </div>
      </div>
      <div className='header-container-mobile display-flex'>
        <button
          className='side-nav'
          aria-label='Menu'
          onClick={() => setOpenNav(true)}
        >
          <img src={burgerIcon} alt='burger' />
        </button>
        <div className='title'>{title}</div>
        <div className='header-right'>
          <div className='search-block' ref={searchRef}>
            <button
              aria-expanded={toggleSearch}
              aria-controls=''
              aria-label='Cauta'
              className='search-button'
              onClick={ModalSearch}
            >
              <IconSearch />
            </button>
            <div className={`search-modal ${toggleSearch ? 'open' : ''}`}>
              <div className='search-container'>
                <input
                  placeholder='Search'
                  type='text'
                  id='search-nav-device'
                />
                <button className='btn-squere-blue'>
                  <IconSearch />
                </button>
              </div>
            </div>
          </div>
          <Dropdown
            className='dropdown-nav-more'
            onClick={(e: any) => {
              if (
                (e.target.id && e.target.id === 'nav-list') ||
                (e.target.parentNode.id &&
                  e.target.parentNode.id === 'nav-list') ||
                e.target.parentNode.parentNode.id ||
                e.target.parentNode.parentNode.id === 'nav-list'
              ) {
                if (!isMenuOpened) {
                  resetMenuDropdown();
                }

                setIsMenuOpened(!isMenuOpened);
              }
            }}
          >
            <Dropdown.Toggle
              className='button-nav blue-btn'
              id='nav-list'
              aria-label='Navigare'
            >
              <IconDetails />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className='dropdown-profile dropdown-list'>
                <button
                  className='dropdown-item'
                  onClick={() => navigate('/my-profile')}
                >
                  <IconUser />
                  <span>Profile</span>
                </button>
                <button
                  className='dropdown-item'
                  onClick={() => resetMenuDropdown()}
                >
                  <IconHeadPhone />
                  <span>Support</span>
                </button>
                <button
                  className='dropdown-item'
                  onClick={() => logoutHandler()}
                >
                  <IconLogOut />
                  <span>Log out</span>
                </button>
              </div>
              <div className='dropdown-nav dropdown-list'>
                <button
                  className={`dropdown-item has-info ${menu.isNotificationMobile ? 'active' : ''}`}
                  aria-controls=''
                  onClick={() =>
                    updateMenuDropdown(
                      'isNotificationMobile',
                      !menu.isNotificationMobile,
                    )
                  }
                >
                  <span className='icon icon-lg'>
                    <IconBell />
                  </span>
                  <span>Notificari</span>
                </button>
                <button
                  className={`dropdown-item ${menu.isCalendarMobile ? 'active' : ''}`}
                  aria-controls=''
                  onClick={() =>
                    updateMenuDropdown(
                      'isCalendarMobile',
                      !menu.isCalendarMobile,
                    )
                  }
                >
                  <span className='icon icon-lg'>
                    <IconCalendar />
                  </span>
                  <span>Calendar</span>
                </button>
              </div>
              <NotificationDropdown
                isNotification={menu.isNotificationMobile}
                isCalendar={menu.isCalendarMobile}
                isProfile={menu.isProfileMobile}
                logout={logoutHandler}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div
        className={
          openNav ? 'mobile-nav-container expanded' : 'mobile-nav-container'
        }
      >
        <div className='display-flex'>
          <Link to='/' className='logo'>
            <img src={Logo} alt='logo' />
            <img src={LogoAdai} alt='logo' />
          </Link>
          <div className='close-btn' onClick={() => setOpenNav(false)}>
            <img src={closeicon} alt='' />
          </div>
        </div>
        <div className='accordion-container'>
          {navData.map((item: AccordionItem) => {
            const sublinksHeight =
              item.sublinks && item?.sublinks?.length
                ? item?.sublinks?.length
                : 1;
            const isActive =
              (
                item.sublinks?.filter(
                  sublink => location.pathname === sublink.link,
                ) ?? []
              ).length > 0 || location.pathname === item.link;
            return (
              <div
                key={item.id}
                className={`accordion-item
                  ${openItems.includes(item.id) || isActive ? 'active' : ''}
                `}
                style={{
                  height: openItems.includes(item.id)
                    ? `${45 + 41 * sublinksHeight}px`
                    : '45px',
                }}
              >
                {item.link ? (
                  <Link to={item.link} className='accordion-button'>
                    <img src={`/${item.icon}.svg`} alt={item.icon} />
                    <p>{item.text}</p>
                    {item.sublinks && (
                      <div
                        className='accordion-button--after'
                        onClick={() => toggleDropdown(item.id)}
                      />
                    )}
                  </Link>
                ) : (
                  <div
                    className='accordion-button'
                    role='button'
                    onClick={() => toggleDropdown(item.id)}
                  >
                    <img src={`/${item.icon}.svg`} alt={item.icon} />
                    <p>{item.text}</p>
                    {item.sublinks && (
                      <div className='accordion-button--after' />
                    )}
                  </div>
                )}
                {item.sublinks && <Sublinks sublinks={item.sublinks} />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
