import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalMobile from '../../components/AnouncesMobile/ModalMobile';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListRequest from '../../components/FilterListRequest/FilterListRequest';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import RequestListItem from '../../components/RequestListItem';
import RequestsFilter from '../../components/RequestsFilter';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { checkAll, toggleChecked } from '../../redux/requests/requests-slice';
import {
  arhiveRequests,
  getRequestFilters,
  getRequests,
  getUserRequests,
  removeRequests,
  restoreRequests,
} from '../../redux/requests/requestsThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import { Filters } from '../../models/Properties';
import { RequestListType } from '../../models/Requests';
import IconPlus from '../../icons/IconPlus';
import './RequestsList.scss';

interface Props {
  title?: string;
}

const tabs = [
  {
    label: 'Solicitari Active',
    value: '1',
  },

  {
    label: 'Solicitari Arhivate',
    value: '4',
  },
  {
    label: 'Solicitari Sterse',
    value: '3',
  },

  {
    label: 'Toate Solicitarile',
    value: '1,4,0,8',
  },
];

const RequestsList: React.FC<Props> = ({ title }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { requests, loading, totalCount } = useAppSelector(
    state => state.requests,
  ).requestsListState;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  const [havePermission, setHavePermission] = useState(true);
  const [prevState, setPrevState] = useState({
    currentPage: 0,
    itemsPerPage: 24,
  });
  const [filtersForm, setFiltersForm] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sort, setSort] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [activeTab, setActiveTab] = useState(tabs[3].value);

  const optionsMock = useCallback(() => {
    switch (activeTab) {
      case '1':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'arhive',
            label: 'Arhiveaza',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];
      case '4':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];

      case '3':
        return [
          {
            value: 'restore',
            label: 'Restaureaza',
          },
        ];
      case '1,4,0,8':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'arhive',
            label: 'Arhiveaza',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];
    }
  }, [activeTab]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  useEffect(() => {
    if (location.pathname === '/requests') {
      setHavePermission(haveUserPermission(Permissions.RequestList));
    } else if (location.pathname === '/my-requests') {
      setHavePermission(haveUserPermission(Permissions.RequestMyList));
    } else if (location.pathname === '/company-requests') {
      setHavePermission(haveUserPermission(Permissions.RequestAgencyList));
    }

    dispatch(getRequestFilters());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getRequestsList = async (params: Filters) => {
    try {
      let result
      if (location.pathname === '/my-requests') {
        result = await dispatch(getUserRequests(params));
      } else {
        result = await dispatch(getRequests(params));
      }

      unwrapResult(result);
    } catch (err: any) {}
  };

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / itemsPerPage);

      setPageCount(pageCount);
    }
  }, [totalCount, itemsPerPage]);

  useEffect(() => {
    let filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      filters: filtersForm,
      sort: sort,
      status: activeTab,
    };

    let newState = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
    };

    if (
      prevState.currentPage !== newState.currentPage ||
      prevState.itemsPerPage !== newState.itemsPerPage
    ) {
      getRequestsList(filters);
      closeModal();
      setPrevState(newState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    } else {
      let filters = {
        offset: currentPage + 1,
        limit: itemsPerPage,
        filters: filtersForm,
        sort: sort,
        status: activeTab,
      };

      getRequestsList(filters);
      closeModal();
      dispatch(checkAll(false));
      setCheckedAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm, sort, activeTab]);

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (requests.some(item => item.checked)) {
      setTitleConfirmModal(`${titleAction} solicitări`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} solicitările selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin o solicitare!');
    }
  };

  const removeConfirmedItems = async () => {
    try {
      const res = await dispatch(
        removeRequests(
          requests
            .filter(item => item.checked)
            .map(checked => checked.id)
            .join(','),
        ),
      );

      unwrapResult(res);

      let filters = {
        offset: currentPage,
        limit: itemsPerPage,
        status: activeTab,
      };

      toast.success('Solicitarile selectate au fost sterse!');
      getRequestsList(filters);
      dispatch(checkAll(false));
      setCheckedAll(false);
    } catch (err: any) {}
  };

  const arhiveConfirmedItems = async () => {
    try {
      const res = await dispatch(
        arhiveRequests(
          requests
            .filter(item => item.checked)
            .map(checked => checked.id)
            .join(','),
        ),
      );

      unwrapResult(res);

      let filters = {
        offset: currentPage,
        limit: itemsPerPage,
        status: activeTab,
      };

      toast.success('Solicitarile selectate au fost arhivate!');
      getRequestsList(filters);
      dispatch(checkAll(false));
      setCheckedAll(false);
    } catch (err: any) {}
  };

  const restoreConfirmedItems = async () => {
    try {
      const res = await dispatch(
        restoreRequests(
          requests
            .filter(item => item.checked)
            .map(checked => checked.id)
            .join(','),
        ),
      );

      unwrapResult(res);

      let filters = {
        offset: currentPage,
        limit: itemsPerPage,
        status: activeTab,
      };

      toast.success('Solicitarile selectate au fost restaurate!');
      getRequestsList(filters);
      dispatch(checkAll(false));
      setCheckedAll(false);
    } catch (err: any) {}
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const editItems = () => {
    if (requests.some(item => item.checked)) {
      navigate(`/requests/${requests.filter(item => item.checked)[0].id}`);
    } else {
      toast.error('Alegeti o solicitare!');
    }
  };

  const exportItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere solicitări') {
      removeConfirmedItems();
    } else if (titleConfirmModal === 'Arhivare solicitări') {
      arhiveConfirmedItems();
    } else if (titleConfirmModal === 'Restaurare solicitări') {
      restoreConfirmedItems();
    }
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const item = requests.find(item => item.id === id);

      if (item && item.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title={title ?? 'Solicitări'}
          subtitle='Completeaza toata campurile obligatorii'
          isbutton={havePermission}
          btnText='Adauga Solicitare'
          btnEvent={() => navigate('/add-request')}
        />
        {havePermission ? (
          <>
            {isMobile ? (
              <div>
                <select
                  className='select-tabs '
                  value={activeTab}
                  onChange={e => setActiveTab(e.target.value)}
                >
                  {tabs.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <Tabs
                id='ads-tabs'
                className='ads-form-tabs'
                activeKey={activeTab}
                onSelect={e => e && setActiveTab(e)}
              >
                {tabs.map(tab => (
                  <Tab
                    key={tab.value}
                    eventKey={tab.value}
                    title={tab.label}
                  ></Tab>
                ))}
              </Tabs>
            )}
            <RequestsFilter setFiltersForm={setFiltersForm} />
            {loading ? (
              <div className='container'>
                <Spinner />
              </div>
            ) : (
              <>
                <FilterListHeader
                  pageCount={pageCount}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalCount={totalCount}
                  count={requests?.length}
                  showCheckedItems={showCheckedItems}
                  checkedItemsLength={
                    requests.filter(item => item.checked).length
                  }
                  setShowCheckedItems={setShowCheckedItems}
                  setItemsPerPage={setItemsPerPage}
                  setCurrentPage={setCurrentPage}
                  handlePageClick={handlePageClick}
                  editItems={editItems}
                  removeItems={() => openConfirmModal('Stergere', 'stergi')}
                  arhiveItems={() => openConfirmModal('Arhivare', 'arhivezi')}
                  restoreItems={() =>
                    openConfirmModal('Restaurare', 'restaurezi')
                  }
                  exportItems={exportItems}
                  options={optionsMock()}
                />
                <FilterListRequest
                  setCheckedAll={(e: boolean) => {
                    setCheckedAll(e);

                    if (e) {
                      handleCheckboxChange(undefined, true);
                    } else {
                      dispatch(checkAll(false));
                    }
                  }}
                  isCheckedAll={isCheckedAll}
                  sortProperty={setSort}
                  sort={sort}
                />
                <div className='list-items-container'>
                  {requests
                    .filter(item => !showCheckedItems || item.checked)
                    .map((item: RequestListType) => (
                      <RequestListItem
                        key={`${item.id} ${item.employeeFullName}`}
                        item={item}
                        checked={item.checked}
                        handleCheckboxChange={handleCheckboxChange}
                      />
                    ))}
                  {!requests.some(
                    item => !showCheckedItems || item.checked,
                  ) && (
                      <p className='empty-text'>
                        {showCheckedItems
                          ? '0 Solicitari selectate'
                          : 'Cautarea ta a returnat 0 Rezultate'}
                      </p>
                    )}
                </div>
                {!showCheckedItems && pageCount > 1 && (
                  <Pagination
                    isSelectPage={false}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    handlePageClick={handlePageClick}
                  />
                )}
              </>
            )}
            <FixedActions
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
              disabledActions={requests.every(item => !item.checked)}
            />
            <div className='add-btn' onClick={() => navigate('/add-request')}>
              <IconPlus />
            </div>
            <ModalMobile
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              removeItems={
                activeTab === '3'
                  ? undefined
                  : () => openConfirmModal('Stergere', 'stergi')
              }
              arhiveItems={
                activeTab === '4' || activeTab === '3'
                  ? undefined
                  : () => openConfirmModal('Arhivare', 'arhivezi')
              }
              restoreItems={
                activeTab === '3'
                  ? () => openConfirmModal('Restaurare', 'restaurezi')
                  : undefined
              }
              exportItems={activeTab === '3' ? undefined : () => exportItems()}
              editItems={activeTab === '3' ? undefined : () => editItems()}
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) {
                  handleCheckboxChange(undefined, true);
                } else {
                  dispatch(checkAll(false));
                }
              }}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default RequestsList;
