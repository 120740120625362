import { createSlice } from '@reduxjs/toolkit';
import { Citizenship } from '../../models/Citizenship';
import { getCitizenships } from './citizenshipsThunk';

type CitizenshipState = {
  citizenships: Citizenship[];
  loading: boolean;
  error: any;
  count: number;
};

const initialState: CitizenshipState = {
  citizenships: [],
  count: 0,
  loading: false,
  error: null,
};

const citizenshipsSlice = createSlice({
  name: 'citizenships',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(getCitizenships.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCitizenships.fulfilled, (state, { payload }) => {
        state.citizenships = payload.citizenships;
        state.count = +payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCitizenships.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = citizenshipsSlice.actions;

export default citizenshipsSlice.reducer;
