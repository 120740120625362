import { unwrapResult } from '@reduxjs/toolkit';
import * as locale from 'date-fns/locale';
import moment from 'moment/moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { DateRangePicker } from 'react-date-range';
import ReactDatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCategories } from '../../redux/categories/categoriesThunk';
import { clearEvaluation } from '../../redux/evaluations/evaluations-slice';
import {
  getAnnouncementsEvaluationByFilters,
  getEvaluationFilters,
  getPricesTypes,
} from '../../redux/evaluations/evaluationsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearStreets,
  clearTowns,
  clearZona,
} from '../../redux/localities/localities-slice';
import {
  getCounty,
  getLocalities,
  getLocations,
  getSpecificTypes,
  getStreet,
  getZona,
} from '../../redux/localities/localitiesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import { parseValues } from '../../helpers/useHelper';
import { Locality } from '../../models/Localities';
import { Filter, SelectValue } from '../../models/Properties';
import {
  contactData,
  roomsData,
} from '../FilterCategory/FilterForm/FiltersForm';
import FormSelect from '../FormSelect';
import Input from '../Input';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import Spinner from '../Spinner';
import './FilterListNewEvaluation.scss';

interface Props {
  setCategoryId: (e: number) => void;
  formFilters: any;
  setFormFilters: (filters: any) => void;
  setFormGraphic: (filters: any) => void;
  setLoading: (e: boolean) => void;
  loading: boolean;
}

const FilterListNewEvaluation: React.FC<Props> = ({
  setCategoryId,
  formFilters,
  setFormFilters,
  setFormGraphic,
  setLoading,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { users } = useAppSelector(state => state.users);
  const { categories } = useAppSelector(state => state.categories);
  const {
    evaluation,
    filters,
    loadingFilters,
    loadingFilterEvaluation,
    pricesTypes,
    error,
  } = useAppSelector(state => state.evaluations);
  const { locations, localities, county, streets, zona, specificTypes } =
    useAppSelector(state => state.localities);

  const [activeCategory, setActiveCategory] = useState<SelectValue>();
  const [countyId, setCountyId] = useState<string | null>(null);
  const [townId, setTownId] = useState<string | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [firstLoad, setFirstLoad] = useState(evaluation ? true : false);

  const myRef = useRef<any>();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getPricesTypes());
    dispatch(getLocations());
    dispatch(getSpecificTypes());
    dispatch(getUsers({}));
    dispatch(getCounty({ parent: 0 }));

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveCategory(parseValues(categories).at(0));
  }, [categories]);

  useEffect(() => {
    if (error) {
      toast.error('Eroare la evaluarea anunturilor: ' + error);
    }
  }, [error]);

  useEffect(() => {
    setFormFilters({});

    if (activeCategory) {
      const categoryId = activeCategory?.value
        ? Number(activeCategory?.value)
        : 0;
      setCategoryId(categoryId);
      dispatch(getEvaluationFilters(categoryId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    if (!formFilters || firstLoad) return;

    let town = filters?.find(
      (filter: Filter) => filter.adTypeView === 'TOWN',
    )?.id;
    let street = filters?.find(
      (filter: Filter) => filter.adTypeView === 'STREET',
    )?.id;
    let zona = filters?.find(
      (filter: Filter) => filter.adTypeView === 'ZONE',
    )?.id;

    if (countyId && town !== undefined) {
      dispatch(getLocalities({ parent: countyId })).then(res => {
        if (parseValues(res.payload)?.length > 0) {
          let clearObject = {};

          if (town)
            clearObject = {
              ...clearObject,
              [town]: parseValues(res.payload)[0],
            };

          if (street) clearObject = { ...clearObject, [street]: [] };

          if (zona && formFilters[zona])
            clearObject = { ...clearObject, [zona]: [] };

          clearObject && setFormFilters({ ...formFilters, ...clearObject });
          setTownId(parseValues(res.payload)[0].value);
        }
      });
    } else {
      dispatch(clearTowns());
      dispatch(clearStreets());
      dispatch(clearZona());

      let clearObject = {};

      if (town) clearObject = { ...clearObject, [town]: null };

      if (street) clearObject = { ...clearObject, [street]: [] };

      if (zona && formFilters[zona])
        clearObject = { ...clearObject, [zona]: [] };

      clearObject && setFormFilters({ ...formFilters, ...clearObject });
      setTownId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countyId]);

  useEffect(() => {
    if (!formFilters || firstLoad) return;

    let street = filters?.find(
      (filter: Filter) => filter.adTypeView === 'STREET',
    )?.id;
    let zona = filters?.find(
      (filter: Filter) => filter.adTypeView === 'ZONE',
    )?.id;

    if (townId && zona !== undefined) {
      dispatch(getZona({ parent: townId }));
    }

    if (townId && street !== undefined) {
      dispatch(getStreet({ town: townId }));

      let clearObject = {};

      if (street) clearObject = { ...clearObject, [street]: [] };

      if (zona && formFilters[zona])
        clearObject = { ...clearObject, [zona]: [] };

      clearObject && setFormFilters({ ...formFilters, ...clearObject });
    } else {
      dispatch(clearStreets());
      dispatch(clearZona());
    }
    let clearObject = {};

    if (street) clearObject = { ...clearObject, [street]: [] };

    if (zona && formFilters[zona]) clearObject = { ...clearObject, [zona]: [] };

    clearObject && setFormFilters({ ...formFilters, ...clearObject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townId]);

  useEffect(() => {
    let hasFilters = false;

    if (formFilters) {
      Object.keys(formFilters).forEach(key => {
        const value = formFilters[key];

        if (value === null) return false;

        if (
          typeof value === 'object' &&
          !Array.isArray(value) &&
          Object.values(value).every(v => v === '')
        ) {
          return false;
        }

        if (Array.isArray(value) && value.length === 0) return false;

        hasFilters = true;
      });
    }
    setDisabled(!hasFilters);
  }, [formFilters]);

  useEffect(() => {
    if (evaluation?.id && id) {
      getLocationsFunctions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation]);

  const getLocationsFunctions = async () => {
    if (!evaluation) return;
    try {
      const resCounty = await dispatch(
        getLocalities({
          parent: evaluation.filters.filter(
            filter => filter.type === 'COUNTY',
          )[0]?.value,
        }),
      );
      unwrapResult(resCounty);

      const resTowns = await dispatch(
        getZona({
          parent: evaluation.filters.filter(filter => filter.type === 'TOWN')[0]
            ?.value,
        }),
      );
      unwrapResult(resTowns);

      getEvaluationInitialFilters(resCounty.payload);
    } catch (err) {}
  };
  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current?.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const handleChange = (
    key: string | number,
    e: string | number | SelectValue | boolean | null | string[],
  ) => {
    setFormFilters({ ...formFilters, [key]: e });
  };

  const handleChangeRange = (
    key: string | number,
    object: string,
    e: string | number | boolean | SelectValue,
  ) => {
    setFormFilters({
      ...formFilters,
      [key]: { ...formFilters[key], [object]: e },
    });
  };

  const handleSelect = (id: number, ranges: any) => {
    setFormFilters({
      ...formFilters,
      [id]: {
        ...formFilters[id],
        from: moment(ranges.selection?.startDate).format('YYYY-MM-DD'),
        to:
          moment(ranges.selection?.endDate).format('YYYY-MM-DD') + ' 23:59:59',
      },
    });
  };

  const filterByGroup = (filter: Filter, position: Number) => {
    return filter.adGroup === position;
  };

  const resetFilters = (e: FormEvent) => {
    e.preventDefault();

    let initialForm = {};

    filters?.forEach((filter: Filter) => {
      if (
        filter.adTypeView === 'RANGE' ||
        filter.adTypeView === 'RANGE_SELECT' ||
        filter.adTypeView === 'PRICE'
      ) {
        initialForm = { ...initialForm, [filter.id]: { from: '', to: '' } };
      } else if (
        filter.adTypeView === 'SELECT_OPTIONS' ||
        filter.adTypeView === 'LOCATION' ||
        filter.adTypeView === 'STREET' ||
        filter.adTypeView === 'SPECIFIC_TYPE' ||
        filter.adTypeView === 'ZONE'
      ) {
        initialForm = { ...initialForm, [filter.id]: [] };
      } else {
        initialForm = { ...initialForm, [filter.id]: null };
      }
    });

    setFormFilters(initialForm);
    setDisabled(true);
    dispatch(clearEvaluation());
  };

  const submitFilters = (e: FormEvent) => {
    e.preventDefault();
    dispatch(clearEvaluation());

    let currentObject: any[] = [];

    filters?.forEach((filter: Filter) => {
      if (
        formFilters[filter.id] &&
        formFilters[filter.id] !== null &&
        formFilters[filter.id] !== ''
      ) {
        if (filter.adTypeView === 'RANGE' || filter.adTypeView === 'PRICE') {
          if (
            formFilters[filter.id]?.from !== '' &&
            formFilters[filter.id]?.from !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formFilters[filter.id]?.from,
            };
          if (
            formFilters[filter.id]?.to !== '' &&
            formFilters[filter.id]?.to !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formFilters[filter.id]?.to,
            };
        }
        if (filter.adTypeView === 'RANGE_SELECT') {
          if (
            formFilters[filter.id]?.from?.value &&
            formFilters[filter.id]?.from?.value !== '' &&
            formFilters[filter.id]?.from?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formFilters[filter.id]?.from?.value,
            };
          if (
            formFilters[filter.id]?.to?.value &&
            formFilters[filter.id]?.to?.value !== '' &&
            formFilters[filter.id]?.to?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formFilters[filter.id]?.to?.value,
            };
        } else if (
          filter.adTypeView === 'COUNTY' ||
          filter.adTypeView === 'TOWN'
        ) {
          if (formFilters[filter.id]?.value)
            currentObject[filter.id] = formFilters[filter.id]?.value;
        } else if (
          filter.adTypeView === 'SELECT' ||
          filter.adTypeView === 'OFFER_TYPE'
        ) {
          currentObject[filter.id] = formFilters[filter.id]?.value;
        } else if (filter.adTypeView === 'EMPLOYEE') {
          if (formFilters[filter.id]?.value?.value)
            currentObject[filter.id] = {
              id: formFilters[filter.id]?.value?.value,
              associated: formFilters[filter.id]?.associated,
            };
        } else if (
          filter.adTypeView === 'SELECT_OPTIONS' ||
          filter.adTypeView === 'LOCATION' ||
          filter.adTypeView === 'STREET' ||
          filter.adTypeView === 'SPECIFIC_TYPE' ||
          filter.adTypeView === 'ZONE'
        ) {
          if (formFilters[filter.id]?.length > 0)
            formFilters[filter.id].forEach((value: number) => {
              currentObject[filter.id] =
                currentObject[filter.id]?.length > 0
                  ? [...currentObject[filter.id], value]
                  : [value];
            });
        } else {
          currentObject[filter.id] = formFilters[filter.id];
        }
      }
    });

    currentObject['0'] = { category: activeCategory?.value };

    currentObject = currentObject.map(item => {
      if (typeof item === 'object' && item !== null) {
        const hasNonEmptyValue = Object.values(item).some(
          value => value !== '' && value !== null,
        );
        return hasNonEmptyValue ? item : null;
      }
      return item;
    });

    const reqFilters = {
      limit: -1,
      category: Number(activeCategory?.value),
      filters: currentObject,
    };
    setFormGraphic(formFilters);
    dispatch(getAnnouncementsEvaluationByFilters(reqFilters));
  };

  const getEvaluationInitialFilters = (localitiesRes: Locality[]) => {
    const newFilters: any = {};
    evaluation?.filters?.forEach((filter: any) => {
      switch (filter.type) {
        case 'OFFER_TYPE':
          newFilters[filter.id] = {
            value: filter.value[0],
            label: filter.value?.includes('1')
              ? 'Vanzare'
              : filter.value?.includes('2')
                ? 'Inchiriere'
                : 'Regim hotelier',
          };
          break;
        case 'COUNTY':
          {
            const filteredLocations = county.filter(location =>
              filter.value.includes(location.id.toString()),
            )[0];

            newFilters[filter.id] = {
              label: filteredLocations?.title,
              value: filteredLocations?.id,
            };
          }
          break;
        case 'TOWN':
          {
            const filteredLocations = localitiesRes.filter(location =>
              filter.value.includes(location.id.toString()),
            )[0];

            newFilters[filter.id] = {
              label: filteredLocations?.title,
              value: filteredLocations?.id,
            };
          }
          break;
        case 'RANGE_SELECT':
          newFilters[filter.id] = {
            from: filter.value?.idFrom ?? null,
            to: filter.value?.idTo ?? null,
          };

          break;
        case 'CHECKBOX':
        case 'VIRTUAL_TUR_CHECKBOX':
        case 'VIDEO_CHECKBOX':
        case 'FLOOR_EXCLUDED_NOT_SPECIFIC_CHECKBOX':
        case 'FLOOR_EXCLUDED_P_LAST_CHECKBOX':
        case 'HAS_CONTRACT_DATA_CHECKBOX':
        case 'EXCLUSIVENESS_CHECKBOX':
          newFilters[filter.id] =
            filter.value === 1 || filter.value === 'true' ? true : false;
          break;
        default:
          newFilters[filter.id] = filter.value;
      }
    });

    setFormGraphic(newFilters);
    setFormFilters(newFilters);
    setTimeout(() => {
      setLoading(false);
      setFirstLoad(false);
    }, 1500);
  };

  const renderContent = (filter: Filter) => {
    switch (filter.adTypeView) {
      case 'SELECT_OPTIONS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formFilters?.[filter.id] ?? null}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'OFFER_TYPE':
        return (
          <FormSelect
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formFilters?.[filter.id] ?? ''}
            key={filter.id}
            options={
              pricesTypes &&
              parseValues(pricesTypes).map(option => {
                return {
                  value: option.value,
                  label:
                    option.label === 'SALE'
                      ? 'Vanzare'
                      : option.label === 'RENT_MONTH'
                        ? 'Inchiriere'
                        : 'Regim hotelier',
                };
              })
            }
          />
        );
      case 'SELECT':
        return (
          <FormSelect
            value={formFilters?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'LOCATION':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formFilters?.[filter.id] ?? null}
            key={filter.id}
            options={locations && parseValues(locations)}
          />
        );
      case 'COUNTY':
        return (
          <FormSelect
            value={formFilters?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setCountyId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={county && parseValues(county)}
          />
        );
      case 'TOWN':
        return (
          <FormSelect
            value={formFilters?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setTownId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={localities && parseValues(localities)}
          />
        );
      case 'STREET':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => {
              handleChange(filter.id, e);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            value={formFilters?.[filter.id] ?? null}
            key={filter.id}
            options={streets && parseValues(streets)}
          />
        );
      case 'ZONE':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formFilters?.[filter.id] ?? null}
            key={filter.id}
            isClearable={true}
            options={zona && parseValues(zona)}
          />
        );
      case 'SELECT_OPTIONS_ROOMS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formFilters?.[filter.id] ?? null}
            key={filter.id}
            isClearable={true}
            options={roomsData}
          />
        );
      case 'SPECIFIC_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formFilters?.[filter.id] ?? null}
            key={filter.id}
            options={specificTypes && parseValues(specificTypes)}
          />
        );
      case 'EMPLOYEE':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formFilters?.[filter.id]?.value ?? ''}
              onChange={(e: SelectValue) =>
                handleChangeRange(filter.id, 'value', e)
              }
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={users && parseValues(users)}
            />
            <Form.Group id='formGridCheckbox' key={filter.id}>
              <Form.Check
                type='checkbox'
                label={'Agent asociat'}
                checked={
                  formFilters?.[filter.id]?.associated === 1 ||
                  formFilters?.[filter.id]?.associated === true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'associated', e.target.checked)
                }
              />
            </Form.Group>
          </div>
        );
      case 'CONTACT_DATA_SELECT':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formFilters?.[filter.id] ?? ''}
              onChange={(e: SelectValue) => handleChange(filter.id, e)}
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={contactData}
            />
          </div>
        );
      case 'INPUT':
      case 'OWNER_PHONE':
      case 'ID':
      case 'STREET_NUMBER':
      case 'KEYWORDS':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                value={formFilters?.[filter.id] || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(filter.id, e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE':
      case 'PRICE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={formFilters?.[filter.id]?.from || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'from', e.target.value)
                }
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={formFilters?.[filter.id]?.to || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'to', e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_SELECT':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formFilters?.[filter.id]?.from}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'from', e)
                }
              />
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formFilters?.[filter.id]?.to}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'to', e)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_DATE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input range-date date-column'>
              {showCalendar && (
                <div className='range-calendar' ref={myRef}>
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: formFilters?.[filter.id]?.from
                          ? new Date(formFilters?.[filter.id]?.from)
                          : undefined,
                        endDate: formFilters?.[filter.id]?.to
                          ? new Date(formFilters?.[filter.id]?.to)
                          : undefined,
                        key: 'selection',
                      },
                    ]}
                    locale={locale.enUS}
                    onChange={e => {
                      handleSelect(filter.id, e);
                    }}
                  />
                </div>
              )}
              <ReactDatePicker
                placeholderText={
                  formFilters?.[filter.id]?.from && formFilters?.[filter.id]?.to
                    ? `${moment(formFilters?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formFilters?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : 'De la/Pana la'
                }
                value={
                  formFilters?.[filter.id]?.from &&
                  formFilters?.[filter.id]?.to &&
                  !showCalendar
                    ? `${moment(formFilters?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formFilters?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : ''
                }
                selected={null}
                onCalendarOpen={() => setShowCalendar(true)}
                onChange={() => {}}
              />
            </div>
          </div>
        );
      case 'CHECKBOX':
      case 'VIRTUAL_TUR_CHECKBOX':
      case 'VIDEO_CHECKBOX':
      case 'FLOOR_EXCLUDED_NOT_SPECIFIC_CHECKBOX':
      case 'FLOOR_EXCLUDED_P_LAST_CHECKBOX':
      case 'HAS_CONTRACT_DATA_CHECKBOX':
      case 'EXCLUSIVENESS_CHECKBOX':
        return (
          <Form.Group id='formGridCheckbox' key={filter.id}>
            <Form.Check
              type='checkbox'
              label={filter.title}
              checked={
                formFilters?.[filter.id] === 1 ||
                formFilters?.[filter.id] === true
                  ? formFilters?.[filter.id]
                  : false
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(filter.id, e.target.checked)
              }
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <div className='new-evaluation-list-filter filter-form-container property-filters-container '>
      <form>
        <div className='filter-item-top'>
          <div className='filter-item'>
            <FormSelect
              key='category-select'
              labelText={true}
              placeholder='Select Options'
              inlineLabel='Tip Proprietate'
              isSearchable={true}
              value={activeCategory}
              options={parseValues(categories) ?? []}
              onChange={(e: SelectValue) => setActiveCategory(e)}
            />
          </div>
        </div>

        {loadingFilters || loading ? (
          <Spinner />
        ) : (
          <>
            <div className={`flex-rows`}>
              {filters && filters.length > 0 && (
                <>
                  <div className='filters-column'>
                    {filters
                      .filter((filter: Filter) => filterByGroup(filter, 1))
                      .map((filter: Filter) => renderContent(filter))}
                  </div>
                  <div className='filters-column'>
                    {filters
                      .filter((filter: Filter) => filterByGroup(filter, 2))
                      .map((filter: Filter) => renderContent(filter))}
                  </div>
                  <div className='filters-column'>
                    {filters
                      .filter((filter: Filter) => filterByGroup(filter, 3))
                      .map((filter: Filter) => renderContent(filter))}
                  </div>
                  <div className='filters-column'>
                    {filters
                      .filter((filter: Filter) => filterByGroup(filter, 4))
                      .map((filter: Filter) => renderContent(filter))}
                  </div>
                  <div className='filters-column'>
                    {filters
                      .filter((filter: Filter) => filterByGroup(filter, 5))
                      .map((filter: Filter) => renderContent(filter))}
                  </div>
                </>
              )}
            </div>
            <div className='filtre-buttons display-flex'>
              <button
                className='button button-border'
                onClick={e => resetFilters(e)}
              >
                Reseteaza
              </button>
              <button
                className='button button-blue'
                disabled={disabled}
                onClick={e => submitFilters(e)}
              >
                Filtreaza
                {loadingFilterEvaluation && (
                  <span
                    style={{
                      position: 'relative',
                      minWidth: '30px',
                      marginLeft: '30px',
                      marginRight: '-30px',
                    }}
                  >
                    <Spinner className='btn-blue-spinner' />
                  </span>
                )}
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default FilterListNewEvaluation;
