import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getSuggestionSubjects = createAsyncThunk<any>(
  'crm/getSuggestionSubjects',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/suggestions-subjects', {
        params: data,
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const createSuggestion = createAsyncThunk<any, any>(
  'crm/createSuggestion',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/crm/suggestions', data);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
