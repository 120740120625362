import { createSlice } from '@reduxjs/toolkit';
import { Filter, Property, PropertyDetails } from '../../models/Properties';
import {
  arhiveProperties,
  cloneProperty,
  createProperty,
  getPropertyFilters,
  getPricesTypes,
  getProperties,
  getProperty,
  getUsersProperties,
  removeProperties,
  updateProperty,
  getCounts,
  getUserCounts,
} from './propertiesThunk';

type PropertiesState = {
  properties: Property[];
  property: PropertyDetails | null;
  counts:any;
  filters: Filter[];
  pricesTypes: any[];
  totalCount: number;
  loading: boolean;
  loadingSave: boolean;
  loadingFilters: boolean;
  error: any;
};

const initialState: PropertiesState = {
  properties: [],
  filters: [],
  property: null,
  pricesTypes: [],
  totalCount: 0,
  loading: false,
  loadingSave: false,
  loadingFilters: false,
  error: null,
  counts:null,
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    toggleChecked(state, action) {
      const item = state.properties.find(item => item.id === action.payload);

      if (item) {
        item.checked = !item.checked;
      }
    },
    checkAll(state, action) {
      state.properties.forEach(item => (item.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProperties.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProperties.fulfilled, (state, { payload }) => {
        state.totalCount = payload.totalCount;
        state.properties = payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getProperties.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getUsersProperties.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersProperties.fulfilled, (state, { payload }) => {
        state.totalCount = payload.totalCount;
        state.properties = payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUsersProperties.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getProperty.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProperty.fulfilled, (state, { payload }) => {
        state.property = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getProperty.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateProperty.pending, state => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(updateProperty.fulfilled, (state, { payload }) => {
        state.property = payload;
        state.loadingSave = false;
        state.error = null;
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingSave = false;
      })
      .addCase(createProperty.pending, state => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(createProperty.fulfilled, (state, { payload }) => {
        state.property = payload;
        state.loadingSave = false;
        state.error = null;
      })
      .addCase(createProperty.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingSave = false;
      })
      .addCase(cloneProperty.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cloneProperty.fulfilled, (state, { payload }) => {
        state.property = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(cloneProperty.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(removeProperties.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeProperties.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(removeProperties.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(arhiveProperties.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(arhiveProperties.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(arhiveProperties.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getPropertyFilters.pending, state => {
        state.loadingFilters = true;
        state.error = null;
      })
      .addCase(getPropertyFilters.fulfilled, (state, { payload }) => {
        state.filters = payload;
        state.loadingFilters = false;
        state.error = null;
      })
      .addCase(getPropertyFilters.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingFilters = false;
      })
      .addCase(getPricesTypes.pending, state => {
        state.error = null;
      })
      .addCase(getPricesTypes.fulfilled, (state, { payload }) => {
        state.pricesTypes = payload;
        state.error = null;
      })
      .addCase(getPricesTypes.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getCounts.pending, state => {
        state.error = null;
      })
      .addCase(getCounts.fulfilled, (state, { payload }) => {
        state.counts = payload;
        state.error = null;
      })
      .addCase(getCounts.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getUserCounts.pending, state => {
        state.error = null;
      })
      .addCase(getUserCounts.fulfilled, (state, { payload }) => {
        state.counts = payload;
        state.error = null;
      })
      .addCase(getUserCounts.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { clearError, toggleChecked, checkAll } = propertiesSlice.actions;

export default propertiesSlice.reducer;
