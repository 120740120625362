interface Props {}

const IconUser: React.FC<Props> = props => {
  return (
    <svg
      width='13'
      height='17'
      viewBox='0 0 13 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label='Profil'
      {...props}
    >
      <path
        id='Color'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7502 16.0546C12.7502 16.5759 12.3431 16.999 11.8394 16.999C11.3358 16.999 10.9287 16.5759 10.9287 16.0546C10.9287 13.4507 8.88596 11.3323 6.37509 11.3323C3.86421 11.3323 1.82145 13.4507 1.82145 16.0546C1.82145 16.5759 1.41436 16.999 0.910727 16.999C0.407095 16.999 0 16.5759 0 16.0546C0 12.409 2.86059 9.44336 6.37509 9.44336C9.88958 9.44336 12.7502 12.409 12.7502 16.0546ZM6.37468 1.88867C7.37921 1.88867 8.19613 2.73585 8.19613 3.77759C8.19613 4.81932 7.37921 5.6665 6.37468 5.6665C5.37014 5.6665 4.55322 4.81932 4.55322 3.77759C4.55322 2.73585 5.37014 1.88867 6.37468 1.88867ZM6.37503 7.55566C8.38409 7.55566 10.0179 5.8613 10.0179 3.77783C10.0179 1.69436 8.38409 0 6.37503 0C4.36596 0 2.73212 1.69436 2.73212 3.77783C2.73212 5.8613 4.36596 7.55566 6.37503 7.55566Z'
      />
    </svg>
  );
};

export default IconUser;
