interface Props {}

const IconBarcode: React.FC<Props> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20.6825 0.923096H17.5818C17.2233 0.923096 16.9327 1.21369 16.9327 1.57213C16.9327 1.93058 17.2233 2.22117 17.5818 2.22117H20.6825C21.287 2.22117 21.7789 2.71306 21.7789 3.31766V6.41829C21.7789 6.77673 22.0695 7.06733 22.4279 7.06733C22.7864 7.06733 23.077 6.77673 23.077 6.41829V3.31766C23.077 1.9973 22.0028 0.923096 20.6825 0.923096Z'
        fill='white'
      />
      <path
        d='M1.57213 7.06733C1.93058 7.06733 2.22117 6.77673 2.22117 6.41829V3.31766C2.22117 2.71306 2.71306 2.22117 3.31762 2.22117H6.41829C6.77673 2.22117 7.06733 1.93058 7.06733 1.57213C7.06733 1.21369 6.77673 0.923096 6.41829 0.923096H3.31762C1.9973 0.923096 0.923096 1.9973 0.923096 3.31766V6.41829C0.923096 6.77673 1.21369 7.06733 1.57213 7.06733Z'
        fill='white'
      />
      <path
        d='M6.41829 21.7788H3.31762C2.71306 21.7788 2.22117 21.2869 2.22117 20.6823V17.5817C2.22117 17.2232 1.93058 16.9326 1.57213 16.9326C1.21369 16.9326 0.923096 17.2232 0.923096 17.5817V20.6823C0.923096 22.0026 1.9973 23.0768 3.31762 23.0768H6.41829C6.77673 23.0768 7.06733 22.7863 7.06733 22.4278C7.06733 22.0694 6.77673 21.7788 6.41829 21.7788Z'
        fill='white'
      />
      <path
        d='M22.4279 16.9326C22.0695 16.9326 21.7789 17.2232 21.7789 17.5817V20.6823C21.7789 21.2869 21.287 21.7788 20.6825 21.7788H17.5818C17.2233 21.7788 16.9327 22.0694 16.9327 22.4278C16.9327 22.7863 17.2233 23.0768 17.5818 23.0768H20.6825C22.0028 23.0768 23.077 22.0026 23.077 20.6823V17.5817C23.077 17.2232 22.7864 16.9326 22.4279 16.9326Z'
        fill='white'
      />
      <path
        d='M15.7058 5.6826H17.7643C18.0455 5.6826 18.2741 5.91128 18.2741 6.1924V8.25089C18.2741 8.60933 18.5647 8.89993 18.9232 8.89993C19.2816 8.89993 19.5722 8.60933 19.5722 8.25089V6.1924C19.5722 5.19552 18.7612 4.38452 17.7643 4.38452H15.7058C15.3474 4.38452 15.0568 4.67512 15.0568 5.03356C15.0568 5.392 15.3474 5.6826 15.7058 5.6826Z'
        fill='white'
      />
      <path
        d='M4.42786 6.1924V8.25089C4.42786 8.60933 4.71845 8.89993 5.07689 8.89993C5.43534 8.89993 5.72593 8.60933 5.72593 8.25089V6.1924C5.72593 5.91128 5.95465 5.6826 6.23573 5.6826H8.29426C8.65271 5.6826 8.9433 5.392 8.9433 5.03356C8.9433 4.67512 8.65271 4.38452 8.29426 4.38452H6.23573C5.23889 4.38452 4.42786 5.19552 4.42786 6.1924Z'
        fill='white'
      />
      <path
        d='M8.29426 18.2308H6.23573C5.95461 18.2308 5.72593 18.0021 5.72593 17.721V15.6625C5.72593 15.304 5.43534 15.0134 5.07689 15.0134C4.71845 15.0134 4.42786 15.304 4.42786 15.6625V17.721C4.42786 18.7178 5.23889 19.5288 6.23573 19.5288H8.29426C8.65271 19.5288 8.9433 19.2382 8.9433 18.8798C8.9433 18.5214 8.65271 18.2308 8.29426 18.2308Z'
        fill='white'
      />
      <path
        d='M19.5722 17.721V15.6625C19.5722 15.304 19.2816 15.0134 18.9232 15.0134C18.5647 15.0134 18.2741 15.304 18.2741 15.6625V17.721C18.2741 18.0021 18.0454 18.2308 17.7643 18.2308H15.7058C15.3474 18.2308 15.0568 18.5214 15.0568 18.8798C15.0568 19.2382 15.3474 19.5288 15.7058 19.5288H17.7643C18.7612 19.5288 19.5722 18.7178 19.5722 17.721Z'
        fill='white'
      />
      <path
        d='M0.966309 12.0001C0.966309 12.3586 1.2569 12.6492 1.61535 12.6492H22.3846C22.743 12.6492 23.0336 12.3586 23.0336 12.0001C23.0336 11.6417 22.743 11.3511 22.3846 11.3511H1.61535C1.2569 11.3511 0.966309 11.6417 0.966309 12.0001Z'
        fill='white'
      />
    </svg>
  );
};

export default IconBarcode;
