// import moment from 'moment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Input from '../Input';
import Spinner from '../Spinner';
import './FilterListContractsTemplates.scss';

interface TemplateContractsFilterForm {
  q?: string;
  id?: string;
  dateCreatedFrom?: string;
  dateCreatedTo?: string;
}

interface Props {
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  onSubmit?: () => void;
  loadingBtn?: boolean;
  setLoadingBtn?: (e: boolean) => void;
}

const FilterListContractsTemplates: React.FC<Props> = ({
  filtersForm,
  setFiltersForm,
  onSubmit,
  loadingBtn,
  setLoadingBtn,
}) => {
  const [formData, setFormData] =
    useState<TemplateContractsFilterForm>(filtersForm);

  useEffect(() => {
    setFormData(filtersForm);
  }, [filtersForm]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (JSON.stringify(formData) !== JSON.stringify(filtersForm)) {
      filter(formData);
      setLoadingBtn && setLoadingBtn(true);
    }
  };

  const resetForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFiltersForm({});
  };

  const filter = (formData: TemplateContractsFilterForm) => {
    formData && setFiltersForm(formData);
    onSubmit && onSubmit();
  };

  return (
    <div className='bg-white contracts-templates-list-filter'>
      <form>
        <div className='filter-item'>
          <Input
            labelText={true}
            value={formData?.q ?? ''}
            inlineLabel={'Cautare'}
            type={'search'}
            placeholder='Căutare'
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, q: e.target.value });
            }}
          />
        </div>
        <div className='filter-item'>
          <Input
            labelText={true}
            value={formData?.id ?? ''}
            inlineLabel={'ID'}
            type={'search'}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, id: e.target.value });
            }}
            placeholder='Id'
          />
        </div>
        <div className='form-group filter-item' key={0}>
          <label className='form-item-group-label'>Data</label>
          <div className='flex-input'>
            <div className='form-item'>
              <DatePicker
                selected={
                  formData.dateCreatedFrom
                    ? new Date(formData.dateCreatedFrom)
                    : null
                }
                placeholderText='De la'
                onChange={e =>
                  setFormData({
                    ...formData,
                    dateCreatedFrom: moment(e).format('YYYY-MM-DD'),
                  })
                }
              />
            </div>
            <div className='form-item'>
              <DatePicker
                selected={
                  formData.dateCreatedTo
                    ? new Date(formData.dateCreatedTo)
                    : null
                }
                placeholderText='Pana la'
                onChange={e =>
                  setFormData({
                    ...formData,
                    dateCreatedTo: moment(e).format('YYYY-MM-DD'),
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className='form-btns-section'>
          <button className='reset-btn' onClick={e => resetForm(e)}>
            Reseteaza{' '}
          </button>
          <button
            className='filter-btn btn-blue'
            onClick={e => handleSubmit(e)}
          >
            {loadingBtn ? <Spinner className='btn-small' /> : 'Filtreaza'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FilterListContractsTemplates;
