import { createAsyncThunk } from '@reduxjs/toolkit';
import { Filters } from '../../models/Announces';
import {
  Evaluation,
  EvaluationBody,
  EvaluationListItem,
} from '../../models/Evaluation';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

type EvaluationsResponse = {
  evaluations: EvaluationListItem[];
  totalCount: number;
};

export const getEvaluations = createAsyncThunk<EvaluationsResponse, any>(
  'crm/getEvaluations',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/evaluations', {
        params,
      });
      const totalCount = response.headers['x-total-count'];

      return { evaluations: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getEvaluation = createAsyncThunk<any, string | number>(
  'crm/getEvaluation',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/evaluations/' + id);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeEvaluation = createAsyncThunk<any, string | number>(
  'crm/removeEvaluation',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete('api/v1/crm/evaluations/' + id);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getEvaluationFilters = createAsyncThunk<any, number | undefined>(
  'crm/getEvaluationFilters',
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/filters', {
        params: {
          ...(categoryId ? { category: categoryId } : {}),
          limit: -1,
          type: 'AD_EVALUATION',
        },
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getPricesTypes = createAsyncThunk<any>(
  'crm/getEvaluationsPricesTypes',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/prices-types');
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getAnnouncementsEvaluationByFilters = createAsyncThunk<
  Evaluation,
  Filters
>('crm/getAnnouncementsEvaluation', async (params, { rejectWithValue }) => {
  try {
    const response = await appAxios.get('api/v1/crm/announcements/evaluation', {
      params,
    });

    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const getEvaluationPdf = createAsyncThunk<any, EvaluationBody>(
  'crm/getEvaluationPdf',
  async (evaluation, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/evaluations/pdf',
        evaluation,
        { responseType: 'blob' },
      );

      return response;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const createEvaluation = createAsyncThunk<any, EvaluationBody>(
  'crm/createEvaluation',
  async (evaluation, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/evaluations',
        evaluation,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const updateEvaluation = createAsyncThunk<any, any>(
  'crm/updateEvaluation',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.put(
        'api/v1/crm/evaluations/' + data.id,
        data.evaluation,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
