import moment from 'moment';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { removeAlert } from '../../redux/announces/announcesThunk';
import { useAppDispatch } from '../../redux/hooks';
import { Alert } from '../../models/Announces';
import IconEye from '../../icons/IconEye';
import IconTrash from '../../icons/IconTrash';
import AlertAddModal from '../AlertAddModal';
import ModalConfirm from '../ModalConfirm';
import './AnnouncesListItemAlerts.scss';

interface Props {
  item: Alert;
  handleCheckboxChange?: (id: number) => void;
  onDeletedAlert: () => void;
}

const AnnouncesListItemAlerts: React.FC<Props> = ({
  item,
  handleCheckboxChange,
  onDeletedAlert,
}) => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const onRemoveAlert = () => {
    setShowDeleteModal(false);
    dispatch(removeAlert(item.id)).then((response: any) => {
      if (response.error) {
        toast.error(
          `Eroare la stergerea alertei ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
        );
      } else {
        toast.success(`Alerta a fost stearsa.`);
        onDeletedAlert();
      }
    });
  };

  return (
    <div
      className={`announces-list-item item-alert ${item.checked && 'active'}`}
    >
      <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={item?.checked}
              readOnly
              onClick={() =>
                handleCheckboxChange && handleCheckboxChange(item.id)
              }
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div>
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='mobile-text'>ID</div>
          <div className='info-nr label-item-mobile'>{item.id}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Titlu</div>
          <div className='info-title label-item-mobile'>{item.title}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Anunturi</div>
          <a
            href={'/ads/' + item.id}
            className='info-anounces-nr label-item-mobile'
          >
            {item.announcements} anunturi noi
          </a>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Ultima vizualizare</div>
          <div className='info-date label-item-mobile'>
            {moment(item.dateViewed).format('DD.MM.YYYY HH:mm:ss')}
          </div>
        </div>
        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            aria-label=''
            onClick={() => setShowViewModal(true)}
          >
            <IconEye />
          </button>
          <button
            className='button-square-md'
            aria-label=''
            onClick={() => setShowDeleteModal(true)}
          >
            <IconTrash />
          </button>
        </div>
      </div>
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere alerta'
        body='Esti sigur ca doresti sa stergi alerta?'
        onConfirmed={onRemoveAlert}
        hideModal={() => setShowDeleteModal(false)}
      />
      {showViewModal && (
        <AlertAddModal
          isModalOpen={showViewModal}
          items={[item]}
          closeModal={() => setShowViewModal(false)}
        />
      )}
    </div>
  );
};

export default AnnouncesListItemAlerts;
