import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLoggedUserId } from '../../helpers/useHelper';
import {
  AccessTokenProps,
  AuthenticationProps,
  ResetPasswordProps,
  TokenProps,
  User,
} from '../../models/Auth';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const authentication = createAsyncThunk<any, AuthenticationProps, any>(
  'auth/fetchAccess',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/authentication', data);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const fetchToken = createAsyncThunk<TokenProps, AccessTokenProps>(
  'auth/fetchToken',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/token', data);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getUserProfile = createAsyncThunk<User, void>(
  'auth/getUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        'api/v1/crm/users/profile',
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/secured/users/logout');
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const forgotPassword = createAsyncThunk<any, string>(
  'auth/forgotPassword',
  async (email, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/users/password/forgot', {
        email: email,
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const resetPassword = createAsyncThunk<any, ResetPasswordProps>(
  'auth/resetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/users/password/reset', data);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
