import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import FormSelect from '../../components/FormSelect';
import Header from '../../components/Header';
import SideNav from '../../components/SideNav';
import GraficImg from '../../assets/svg/grafic.svg';
import './SlotsUse.scss';



const SlotsUse: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityClicked, setActivityClicked] = useState<number | null>(null);

  const closeModal = () => {
    setIsModalOpen(false);
    setActivityClicked(null);
    // prepareRequestParams();
  };
  return (
    <>
      <div className='flex-container display-flex'>
        <SideNav />
        <div className='display-flex flex-column main-wrapper'>
          <Header
            title='Utilizare sloturi'
            subtitle='Vezi statistici despre utilizarea sloturilor'
            isbutton={false}
            backBtn={false}
            btnText=''
          />

          <>
            <div className='section-container slots-use-section'>
              <div className='bg-white slots-use-left'>
                <h2 className='heading-block'>Filter</h2>
                <form action='' className='form-block'>
                  <div className='form-item'>
                    <FormSelect
                      labelText={true}
                      placeholder='Select Options'
                      inlineLabel='Website'
                      isSearchable={true}
                    />
                  </div>
                  <div className='date-picker'>
                    <label>Selecteaza perioda</label>
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                    />
                  </div>

                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Agent'
                    />
                  </div>

                  <Form.Group className='form-group' id='type-news'>
                    <label htmlFor='' className='form-item-group-label'>
                      Tip anunt
                    </label>
                    <Form.Check
                      type='checkbox'
                      label='Anunturi Basic'
                      // checked={isChecked}
                      // onChange={handleCheckboxChange}
                    />

                    <Form.Check
                      type='checkbox'
                      label='Anunturi Premium'
                      // checked={isChecked}
                      // onChange={handleCheckboxChange}
                    />

                    <Form.Check
                      type='checkbox'
                      label='Anunturi Up!'
                      // checked={isChecked}
                      // onChange={handleCheckboxChange}
                    />

                    <Form.Check
                      type='checkbox'
                      label='Anunturi Spotlight'
                      // checked={isChecked}
                      // onChange={handleCheckboxChange}
                    />
                  </Form.Group>

                  <div className='buttons-group'>
                    <button className='reset-btn '>Reseteaza</button>
                    <button className='button-blue button-md'>Filtreaza</button>
                  </div>
                </form>
              </div>
              <div className='bg-white slots-use-right '>
                <img src={GraficImg} alt='' />
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default SlotsUse;
