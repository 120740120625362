import { createAsyncThunk } from '@reduxjs/toolkit';
import { Filters, LabelsParams } from '../../models/Announces';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getAnnounces = createAsyncThunk<any, Filters | any>(
  'auth/getAnnounces',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/announcements', {
        params,
      });

      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getMapAnnounces = createAsyncThunk<any, Filters | any>(
  'auth/getMapAnnounces',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/announcements/map', {
        params,
      });

      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// filters
export const getAnnouncesFilters = createAsyncThunk<any, number | undefined>(
  'auth/getAnnouncesFilters',
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/filters', {
        params: { category: categoryId, limit: -1, type: 'AD' },
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const saveAnnounce = createAsyncThunk<any, number | undefined>(
  'crm/saveAnnounce',
  async (announceId, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        `api/v1/crm/announcements/${announceId}/save`,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const checkAnnouncesCount = createAsyncThunk<any, Filters | any>(
  'crm/checkAnnouncesCount',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/announcements', {
        params,
      });

      return response.headers['x-total-count'];
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const setLabels = createAsyncThunk<any, LabelsParams>(
  'crm/announcements/setLabels',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        `api/v1/crm/announcements/${params.announcementId}/labels`,
        {
          ids: params.labelsIds,
        },
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const exportAnnouncements = createAsyncThunk<any, number[]>(
  'crm/exportAnnouncements',
  async (announceIds, { rejectWithValue }) => {
    try {
      const params = {
        ids: announceIds,
      };

      const response = await appAxios.post(
        `api/v1/crm/announcements/export`,
        params,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const saveAnnounceSearch = createAsyncThunk<any, any>(
  'crm/saveAnnounceSearch',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        `api/v1/crm/announcements-searches`,
        data,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeAlert = createAsyncThunk<any, any>(
  'crm/removeAlert',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete(
        `api/v1/crm/announcements-searches/${id}`,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeAlerts = createAsyncThunk<any, any>(
  'crm/removeAlerts',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete(
        `api/v1/crm/announcements-searches`,
        { params: { ids: ids } },
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getAnnounceAlerts = createAsyncThunk<any, any>(
  'crm/getAnnounceAlerts',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/announcements-searches`, {
        params,
      });

      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getAnnouncePopupAlerts = createAsyncThunk<any, any>(
  'crm/getAnnouncePopupAlerts',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/announcements-searches`, {
        params,
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getAnnounceAlert = createAsyncThunk<any, any>(
  'crm/getAnnounceAlert',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        `api/v1/crm/announcements-searches/${id}/reset`,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
