import React from 'react';
import Stackicon from '../../assets/svg/penicon.svg';
import './ActionsMobileContract.scss';

interface Props {
  editItems?: () => void;
}
const ActionsMobileContract: React.FC<Props> = ({ editItems }) => {
  return (
    <>
      <div className='actions-container'>
        <div
          className='action-item'
          onClick={e => {
            editItems && editItems();
          }}
        >
          <p>Modifica contract </p>
          <img src={Stackicon} alt='Stackicon' />
        </div>
      </div>
    </>
  );
};

export default ActionsMobileContract;
