import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Contract,
  ContractPreview,
  ContractTemplate,
  ContractTemplateField,
} from '../../models/Contract';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export type ContractsReponse = {
  contracts: Contract[];
  totalCount: number;
};

export const getContractsTemplates = createAsyncThunk<ContractTemplate[], any>(
  'crm/getContractsTemplates',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/contracts-templates', {
        params: data,
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getContractTemplateFields = createAsyncThunk<
  ContractTemplateField[],
  any
>('crm/getContractTemplateFields', async (data, { rejectWithValue }) => {
  try {
    const response = await appAxios.get(
      'api/v1/crm/contracts-templates-fields',
      {
        params: data,
      },
    );
    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const getContractTemplate = createAsyncThunk<ContractTemplate, any>(
  'crm/getContractTemplate',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        'api/v1/crm/contracts-templates/' + id,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getContracts = createAsyncThunk<ContractsReponse, any>(
  'crm/getContracts',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/contracts', {
        params: data,
      });
      const totalCount = response.headers['x-total-count'];

      return { contracts: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getContract = createAsyncThunk<any, any>(
  'crm/getContract',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/contracts/' + data);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const createContract = createAsyncThunk<any, any>(
  'crm/createContract',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/crm/contracts', data);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const updateContract = createAsyncThunk<any, any>(
  'crm/updateContract',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.put(
        'api/v1/crm/contracts/' + data.id,
        data.data,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const previewContract = createAsyncThunk<any, ContractPreview>(
  'crm/previewContract',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/contracts/preview',
        data,
        { responseType: 'blob' },
      );

      return response;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const generateQrCode = createAsyncThunk<any, any>(
  'crm/generateQrCode',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/contracts/generate-qr',
        {},
      );

      return response;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getCiToken = createAsyncThunk<any, any>(
  'crm/getCiToken',
  async (token, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        'api/v1/crm/contracts/get-ci/' + token,
      );

      return response;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const scanCi = createAsyncThunk<any, any>(
  'crm/scanCi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/contracts/scan-ci', data);

      return response;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeContracts = createAsyncThunk<string, string>(
  'crm/removeContracts',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete('api/v1/crm/contracts', {
        params: {
          ids: ids,
        },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const exportContracts = createAsyncThunk<any, any>(
  'crm/exportContracts',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/contracts/export', {
        params: {
          ids: data.ids,
          type: data.type,
        },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const postContractSign = createAsyncThunk<any, any>(
  'crm/postContractSign',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/contracts/' + data + '/sign',
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);


export const postSignCallback = createAsyncThunk<any, any>(
  'crm/postContractSign',
  async (envelopeId, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/contracts/sign/'+envelopeId,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
