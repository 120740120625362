import { useAppSelector } from '../../redux/hooks';
import Input from '../Input';
import Spinner from '../Spinner';
import './ReportsAgentsTable.scss';

interface Props {}

const ReportsAgentsTable: React.FC<Props> = () => {
  const { agentsStatistics, loadingReport } = useAppSelector(
    state => state.reports,
  );
  const { activitiesTypes } = useAppSelector(state => state.activities);

  return (
    <div className='reports-agents-wrapper'>
      {loadingReport ? (
        <div className='container'>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='reports-agents-table'>
            <div className='reports-agents-item-thead'>
              <div className='reports-agents-item desktop-titles'>
                <div className='slot-column'>Agent</div>
                <div className='slot-column'>Proprietati noi</div>
                <div className='slot-column'>Solicitari noi</div>
                <div className='slot-column'>Contacte noi</div>
                {activitiesTypes.map(activityType => (
                  <div className='slot-column'>
                    <span>{activityType.title}</span>
                  </div>
                ))}
              </div>
            </div>
            {agentsStatistics?.map((agent, index) => (
              <div className='reports-agents-item' key={'agent-' + index}>
                <div className='column-item'>
                  <div className='mobile-text'>Agent</div>
                  <div className='contact-details'>
                    <div className='info-contact-details'>
                      {agent?.fullName}
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Proprietati noi</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <Input
                        type='number'
                        value={agent.propertiesCount}
                        disabled
                        classNameInput='pill'
                      />
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Solicitari noi</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <Input
                        type='number'
                        value={agent.requestsCount}
                        disabled
                        classNameInput='pill'
                      />
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Contacte noi</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <Input
                        type='number'
                        value={agent.contactsCount}
                        disabled
                        classNameInput='pill'
                      />
                    </div>
                  </div>
                </div>

                {agent?.activitiesGroupedCount?.map(group => (
                  <div className='column-item'>
                    <div className='mobile-text'>{group.typeTitle}</div>
                    <div className='slot-column'>
                      <div className='content-numbers'>
                        <Input
                          type='number'
                          value={group.activitiesCount}
                          disabled
                          classNameInput='pill'
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ReportsAgentsTable;
