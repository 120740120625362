import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '../../assets/svg/closemodal.svg';
import ActionsMobile from '../ActionsMobile';
import FilterListContractsTemplates from '../FilterListContractsTemplates/FilterListContractsTemplates';
import SearchMobile from '../SearchMobile';
import './ContractTemplatesModal.scss';

interface Props {
  isModalOpen: boolean;
  isAction: boolean;
  isSearch: boolean;
  isFilter: boolean;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  closeModal: () => void;
}

const ContractTemplatesModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  isAction,
  isSearch,
  isFilter,
  filtersForm,
  setFiltersForm,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={
          isFilter || isAction ? 'mobile-modal filter-overflow' : 'mobile-modal'
        }
      >
        <Modal.Body>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          {isSearch && <SearchMobile />}
          {isAction && <ActionsMobile />}
          {isFilter && <FilterListContractsTemplates
            filtersForm={filtersForm}
            setFiltersForm={setFiltersForm}
            onSubmit={closeModal}/>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContractTemplatesModal;
