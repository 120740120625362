import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { useEffect, useState } from 'react';
import FixedActions from '../../components/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ReportsEstateChart from '../../components/ReportsEstateChart';
import ReportsEstateTable from '../../components/ReportsEstateTable';
import ReportsFilter from '../../components/ReportsFilter';
import ReportsModal from '../../components/ReportsModal';
import SideNav from '../../components/SideNav';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getEstateReports } from '../../redux/reports/reportsThunk';
import useHelper, { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import { ReportEstateItem } from '../../models/Report';
import './ReportsEstates.scss';

interface Props {}

const ReportsEstates: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const { estateDemands } = useAppSelector(state => state.reports);
  const { auth } = useAppSelector(state => state.auth);
  //eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.Reports),
  );
  const [filtersForm, setFiltersForm] = useState<any>({
    employees: auth ? [auth?.id] : [],
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };
  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };
  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getReports();
    //eslint-disable-next-line
  }, [filtersForm]);

  const getReports = async () => {
    try {
      const response = await dispatch(getEstateReports(filtersForm));
      unwrapResult(response);
    } catch (err) {
      diplayErrorMessages(err);
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Raport Proprietati si Cereri adaugate'
          subtitle='Vezi evolutia proprietatilor si a cererilor adaugate'
          isbutton={false}
          btnText=''
        />
        {havePermission ? (
          <div className='display-flex flex-column '>
            <ReportsFilter
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
            />
            <div className='display-flex flex-column report-estate-wrapper'>
              {estateDemands.map((report: ReportEstateItem) => (
                <div className='bg-white report-estate-container'>
                  <h3 className='title'>{report.fullName}</h3>
                  <div className='display-flex'>
                    <ReportsEstateTable rows={report.rows} />
                    <ReportsEstateChart row={report} />
                  </div>
                </div>
              ))}
            </div>
            <FixedActions
              disabledActions={true}
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <ReportsModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
            />
          </div>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default ReportsEstates;
