import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AnnouncesAlertsModal from '../../components/AnnouncesAlertsModal/AnnouncesAlertsModal';
import AnnouncesListItemAlerts from '../../components/AnnouncesListItemAlerts';
import FilterListAlerts from '../../components/FilterListAlerts/FilterListAlerts';
import FilterListHeader from '../../components/FilterListHeader';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import {
  checkAlertsAll,
  toggleAlertsChecked,
} from '../../redux/announces/announces-slice';
import {
  getAnnounceAlerts,
  removeAlerts,
} from '../../redux/announces/announcesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { haveUserPermission } from '../../helpers/useHelper';
import { Alert } from '../../models/Announces';
import { Permissions } from '../../models/Permissions';
import './AnnouncesAlerts.scss';

interface Props {}

const options = [
  {
    value: 'remove',
    label: 'Sterge',
  },
];

const AnnouncesAlerts: React.FC<Props> = () => {
  const { announceAlerts, totalCountAlerts, announceAlertsLoading } =
    useAppSelector(state => state.announces);
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.AdAlertList),
  );
  const [sort, setSort] = useState('');
  const [prevState, setPrevState] = useState({
    currentPage: 0,
    itemsPerPage: 24,
  });

  useEffect(() => {
    loadAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (totalCountAlerts) {
      const pageCount = Math.ceil(totalCountAlerts / itemsPerPage);

      setPageCount(pageCount);
    }
  }, [totalCountAlerts, itemsPerPage]);

  useEffect(() => {
    let newState = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
    };

    if (
      JSON.stringify(prevState.currentPage) !==
        JSON.stringify(newState.currentPage) ||
      JSON.stringify(prevState.itemsPerPage) !==
        JSON.stringify(newState.itemsPerPage)
    ) {
      loadAlerts();
      setPrevState(newState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    } else {
      loadAlerts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const item = announceAlerts.find(item => item.id === id);

      if (item && item.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleAlertsChecked(id));
    }

    if (all) {
      dispatch(checkAlertsAll(true));
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (announceAlerts.some(announceAlert => announceAlert.checked)) {
      setTitleConfirmModal(`${titleAction} alerte anunturi`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} alertele selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin o alerta!');
    }
  };

  const removeConfirmedItems = () => {
    dispatch(
      removeAlerts(
        announceAlerts
          .filter(al => al.checked)
          .map(al => al.id)
          .join(','),
      ),
    ).then((res: any) => {
      if (res.type === 'crm/removeAlerts/fulfilled') {
        toast.success('Alertele selectate au fost sterse!');
        dispatch(checkAlertsAll(false));
        closeModal();
        loadAlerts();
      }
    });
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere alerte anunturi') {
      removeConfirmedItems();
    }
  };

  const loadAlerts = () => {
    const filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      sort: sort,
    };

    dispatch(getAnnounceAlerts(filters));

    closeModal();
    dispatch(checkAlertsAll(false));
    setCheckedAll(false);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Alerte ImoAP'
          subtitle='Alertele vor fi trimise pe email'
          isbutton={false}
          btnText=''
        />
        {havePermission ? (
          <>
            <FilterListHeader
              pageCount={pageCount}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalCount={totalCountAlerts}
              count={announceAlerts?.length}
              showCheckedItems={showCheckedItems}
              checkedItemsLength={
                announceAlerts.filter(item => item.checked).length
              }
              setShowCheckedItems={setShowCheckedItems}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
              handlePageClick={handlePageClick}
              showActions={true}
              options={options}
              removeItems={() => openConfirmModal('Stergere', 'stergi')}
            />
            <FilterListAlerts
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) {
                  handleCheckboxChange(undefined, true);
                } else {
                  dispatch(checkAlertsAll(false));
                }
              }}
              isCheckedAll={isCheckedAll}
              setSort={setSort}
              sort={sort}
            />
            <div className='list-items-container'>
              {announceAlertsLoading ? (
                <Spinner />
              ) : (
                <>
                  {announceAlerts
                    .filter(item => !showCheckedItems || item.checked)
                    .map((alert: Alert) => (
                      <AnnouncesListItemAlerts
                        item={alert}
                        key={alert.id}
                        onDeletedAlert={loadAlerts}
                        handleCheckboxChange={handleCheckboxChange}
                      />
                    ))}
                  {!announceAlerts.some(
                    item => !showCheckedItems || item.checked,
                  ) && (
                    <p className='empty-text'>
                      {showCheckedItems
                        ? '0 Anunturi selectati'
                        : 'Cautarea ta a returnat 0 Rezultate'}
                    </p>
                  )}
                </>
              )}
            </div>
            {!showCheckedItems && pageCount > 1 && (
              <Pagination
                isSelectPage={false}
                pageCount={pageCount}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                handlePageClick={handlePageClick}
              />
            )}{' '}
            <FixedActions
              disabledActions={true}
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <AnnouncesAlertsModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              setSort={setSort}
              sort={sort}
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) {
                  handleCheckboxChange(undefined, true);
                } else {
                  dispatch(checkAlertsAll(false));
                }
              }}
              isCheckedAll={isCheckedAll}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default AnnouncesAlerts;
