interface Props {}

const IconCopy: React.FC<Props> = props => {
  return (
    <svg
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9.45614 7.9598V1.41699C9.45614 0.64168 8.8252 0.0107422 8.04989 0.0107422H1.50708C0.731768 0.0107422 0.10083 0.64168 0.10083 1.41699V7.9598C0.10083 8.73512 0.731768 9.36605 1.50708 9.36605H8.04989C8.8252 9.36605 9.45614 8.73512 9.45614 7.9598ZM1.03833 7.9598V1.41699C1.03833 1.15855 1.24864 0.948242 1.50708 0.948242H8.04989C8.30833 0.948242 8.51864 1.15855 8.51864 1.41699V7.9598C8.51864 8.21824 8.30833 8.42855 8.04989 8.42855H1.50708C1.24864 8.42855 1.03833 8.21824 1.03833 7.9598ZM11.4927 3.63355H10.3533C10.0946 3.63355 9.88458 3.84324 9.88458 4.1023C9.88458 4.36137 10.0946 4.57105 10.3533 4.57105H11.4927C11.7511 4.57105 11.9615 4.78137 11.9615 5.0398V11.5826C11.9615 11.8411 11.7511 12.0514 11.4927 12.0514H4.95021C4.69177 12.0514 4.48146 11.8411 4.48146 11.5826V10.2867C4.48146 10.0279 4.27177 9.81793 4.01271 9.81793C3.75364 9.81793 3.54396 10.0279 3.54396 10.2867V11.5826C3.54396 12.3579 4.17489 12.9889 4.95021 12.9889H11.4927C12.268 12.9889 12.899 12.3579 12.899 11.5826V5.0398C12.899 4.26449 12.268 3.63355 11.4927 3.63355Z' />
    </svg>
  );
};

export default IconCopy;
