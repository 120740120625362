import { createSlice } from '@reduxjs/toolkit';
import { Suggestion, SuggestionSubject } from '../../models/Suggestions';
import { createSuggestion, getSuggestionSubjects } from './suggestionsThunk';

type SuggestionState = {
  suggestionsSubjects: SuggestionSubject[];
  suggestion: Suggestion | null;
  loadingSubjects: boolean;
  loading: boolean;
  error: any;
};

const initialState: SuggestionState = {
  suggestionsSubjects: [],
  loading: false,
  loadingSubjects: false,
  error: null,
  suggestion: null,
};

const suggestionsSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSuggestionSubjects.pending, state => {
        state.loadingSubjects = true;
        state.error = null;
      })
      .addCase(getSuggestionSubjects.fulfilled, (state, { payload }) => {
        state.suggestionsSubjects = payload;
        state.loadingSubjects = false;
        state.error = null;
      })
      .addCase(getSuggestionSubjects.rejected, (state, action) => {
        state.error = null;
        state.loadingSubjects = false;
      })
      .addCase(createSuggestion.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSuggestion.fulfilled, (state, { payload }) => {
        state.suggestion = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(createSuggestion.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default suggestionsSlice.reducer;
