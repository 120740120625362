interface Props {}

const IconHome: React.FC<Props> = (props) => {
 return (
  <svg
   xmlns="http://www.w3.org/2000/svg"
   width="24"
   height="24"
   viewBox="0 0 24 24"
   fill="none"
  >
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16 19.9877H18.99L19 11.6117L11.998 4.41975L5.006 11.5708L5 19.9877H8V12.9878C8 12.4347 8.447 11.9878 9 11.9878H15C15.552 11.9878 16 12.4347 16 12.9878V19.9877ZM14 19.9877H10V13.9878H14V19.9877ZM20.424 10.1728L12.715 2.28875C12.338 1.90375 11.662 1.90375 11.285 2.28875L3.575 10.1737C3.21 10.5487 3 11.0728 3 11.6117V19.9877C3 21.0907 3.847 21.9877 4.888 21.9877H9H15H19.111C20.152 21.9877 21 21.0907 21 19.9877V11.6117C21 11.0728 20.79 10.5488 20.424 10.1728Z"
    fill="#33475B"
   />
  </svg>
 );
};

export default IconHome;
