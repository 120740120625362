import * as locale from 'date-fns/locale';
import moment from 'moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import ReactDatePicker from 'react-datepicker';
import {
  contactData,
  roomsData,
} from '../../components/FilterCategory/FilterForm/FiltersForm';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input';
import MultiselectCheckboxes from '../../components/MultiselectCheckboxes';
import Spinner from '../../components/Spinner';
import {
  setStateCounty,
  setStateTown,
} from '../../redux/announces/announces-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearTowns, clearZona } from '../../redux/localities/localities-slice';
import { getLocalities, getZona } from '../../redux/localities/localitiesThunk';
import { parseValues } from '../../helpers/useHelper';
import { Filter, SelectValue } from '../../models/Properties';
import IconClose from '../../icons/IconClose';

interface Props {
  openedFilters: boolean;
  setOpenedFilters: (e: boolean) => void;
  filtersForm?: any;
  setFiltersForm?: (e: any) => void;
  activeCategory?: number;
  setActiveCategory?: (e: number) => void;
  loadingBtn?: boolean;
  setLoadingBtn?: (e: boolean) => void;
}
const MapFilters: React.FC<Props> = ({
  openedFilters,
  setOpenedFilters,
  filtersForm,
  setFiltersForm,
  activeCategory,
  setActiveCategory,
  loadingBtn = false,
  setLoadingBtn,
}) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>(
    filtersForm?.length > 0
      ? {
          ...filtersForm.map((filter: any, index: number) => filter),
        }
      : {},
  );

  const { loadingFilters, pricesTypes } = useAppSelector(
    state => state.properties,
  );
  const { categories } = useAppSelector(state => state.categories);
  const { users } = useAppSelector(state => state.users);
  const { filters, stateCountyId, stateTownId } = useAppSelector(
    state => state.announces,
  );
  const { locations, localities, county, streets, zona, specificTypes } =
    useAppSelector(state => state.localities);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [countyId, setCountyId] = useState<string | null>(stateCountyId);
  const [townId, setTownId] = useState<string | null>(stateTownId);
  const myRef = useRef<any>();

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  useEffect(() => {
    if (!formData) return;

    let town = filters?.find(
      (filter: Filter) => filter.adTypeView === 'TOWN',
    )?.id;
    let zona = filters?.find(
      (filter: Filter) => filter.adTypeView === 'ZONE',
    )?.id;

    if (countyId && town !== undefined) {
      dispatch(getLocalities({ parent: countyId })).then(res => {
        if (parseValues(res.payload)?.length > 0) {
          let clearObject = {};
          if (town)
            clearObject = {
              ...clearObject,
              [town]: parseValues(res.payload)[0],
            };
          if (zona) clearObject = { ...clearObject, [zona]: [] };
          clearObject && setFormData({ ...formData, ...clearObject });
          setTownId(parseValues(res.payload)[0].value);
        }
      });
    } else {
      dispatch(clearTowns());
      dispatch(clearZona());
      let clearObject = {};
      if (town) clearObject = { ...clearObject, [town]: null };
      if (zona) clearObject = { ...clearObject, [zona]: [] };
      clearObject && setFormData({ ...formData, ...clearObject });
      setTownId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countyId]);

  useEffect(() => {
    if (!formData) return;

    let zona = filters?.find(
      (filter: Filter) => filter.adTypeView === 'ZONE',
    )?.id;
    if (townId && zona !== undefined) {
      dispatch(getZona({ parent: townId }));

      let clearObject = {};

      if (zona) clearObject = { ...clearObject, [zona]: [] };
      clearObject && setFormData({ ...formData, ...clearObject });
    } else {
      dispatch(clearZona());

      let clearObject = {};
      if (zona) clearObject = { ...clearObject, [zona]: [] };

      clearObject && setFormData({ ...formData, ...clearObject });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townId]);

  const handleChange = (
    key: string | number,
    e: string | number | SelectValue | boolean | null | string[],
  ) => {
    setFormData({ ...formData, [key]: e });
  };

  const handleChangeRange = (
    key: string | number,
    object: string,
    e: string | number | boolean | SelectValue,
  ) => {
    setFormData({ ...formData, [key]: { ...formData[key], [object]: e } });
  };

  const handleSelect = (id: number, ranges: any) => {
    setFormData({
      ...formData,
      [id]: {
        ...formData[id],
        from: moment(ranges.selection?.startDate).format('YYYY-MM-DD'),
        to:
          moment(ranges.selection?.endDate).format('YYYY-MM-DD') + ' 23:59:59',
      },
    });
  };

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current?.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const submitFilters = (e: FormEvent) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setLoadingBtn && setLoadingBtn(true);
    let currentObject: any[] = [
      {
        category: activeCategory,
      },
    ];
    filters?.forEach((filter: Filter) => {
      if (
        formData[filter.id] &&
        formData[filter.id] !== null &&
        formData[filter.id] !== ''
      ) {
        if (filter.adTypeView === 'RANGE' || filter.adTypeView === 'PRICE') {
          if (
            formData[filter.id]?.from !== '' &&
            formData[filter.id]?.from !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formData[filter.id]?.from,
            };
          if (
            formData[filter.id]?.to !== '' &&
            formData[filter.id]?.to !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formData[filter.id]?.to,
            };
        }
        if (filter.adTypeView === 'RANGE_SELECT') {
          if (
            formData[filter.id]?.from?.value &&
            formData[filter.id]?.from?.value !== '' &&
            formData[filter.id]?.from?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formData[filter.id]?.from?.value,
            };
          if (
            formData[filter.id]?.to?.value &&
            formData[filter.id]?.to?.value !== '' &&
            formData[filter.id]?.to?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formData[filter.id]?.to?.value,
            };
        } else if (
          filter.adTypeView === 'COUNTY' ||
          filter.adTypeView === 'TOWN'
        ) {
          if (formData[filter.id]?.value)
            currentObject[filter.id] = formData[filter.id]?.value;
        } else if (filter.adTypeView === 'SELECT') {
          currentObject[filter.id] = formData[filter.id]?.value;
        } else if (filter.adTypeView === 'EMPLOYEE') {
          if (formData[filter.id]?.value?.value)
            currentObject[filter.id] = {
              id: formData[filter.id]?.value?.value,
              associated: formData[filter.id]?.associated,
            };
        } else if (
          filter.adTypeView === 'SELECT_OPTIONS' ||
          filter.adTypeView === 'LOCATION' ||
          filter.adTypeView === 'STREET' ||
          filter.adTypeView === 'SPECIFIC_TYPE' ||
          filter.adTypeView === 'ZONE' ||
          filter.adTypeView === 'OFFER_TYPE'
        ) {
          if (formData[filter.id]?.length > 0)
            formData[filter.id].forEach((value: number) => {
              currentObject[filter.id] =
                currentObject[filter.id]?.length > 0
                  ? [...currentObject[filter.id], value]
                  : [value];
            });
        } else {
          currentObject[filter.id] = formData[filter.id];
        }
      }
    });

    currentObject?.length > 0 &&
      setFiltersForm &&
      setFiltersForm(currentObject);
    dispatch(setStateCounty(countyId));
    dispatch(setStateTown(townId));
    isMobile && setOpenedFilters(false);
  };

  const resetFilters = (e: FormEvent) => {
    e.preventDefault();

    let initialForm = {};

    filters?.forEach((filter: Filter) => {
      if (
        filter.adTypeView === 'RANGE' ||
        filter.adTypeView === 'RANGE_SELECT' ||
        filter.adTypeView === 'PRICE'
      ) {
        initialForm = { ...initialForm, [filter.id]: { from: '', to: '' } };
      } else if (
        filter.adTypeView === 'SELECT_OPTIONS' ||
        filter.adTypeView === 'LOCATION' ||
        filter.adTypeView === 'STREET' ||
        filter.adTypeView === 'SPECIFIC_TYPE' ||
        filter.adTypeView === 'ZONE' ||
        filter.adTypeView === 'OFFER_TYPE'
      ) {
        initialForm = { ...initialForm, [filter.id]: [] };
      } else {
        initialForm = { ...initialForm, [filter.id]: null };
      }
    });

    setFormData(initialForm);
    setFiltersForm && setFiltersForm(null);
    setActiveCategory && setActiveCategory(0);
    dispatch(setStateCounty(null));
    dispatch(setStateTown(null));
    isMobile && setOpenedFilters(false);
  };

  const renderContent = (filter: Filter) => {
    switch (filter.adTypeView) {
      case 'SELECT_OPTIONS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'OFFER_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={
              pricesTypes &&
              parseValues(pricesTypes).map(option => {
                return {
                  value: option.value,
                  label:
                    option.label === 'SALE'
                      ? 'Vanzare'
                      : option.label === 'RENT_MONTH'
                        ? 'Inchiriere'
                        : 'Regim hotelier',
                };
              })
            }
          />
        );
      case 'SELECT':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'LOCATION':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={locations && parseValues(locations)}
          />
        );
      case 'COUNTY':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setCountyId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={county && parseValues(county)}
          />
        );
      case 'TOWN':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setTownId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={localities && parseValues(localities)}
          />
        );
      case 'STREET':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => {
              handleChange(filter.id, e);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={streets && parseValues(streets)}
          />
        );
      case 'ZONE':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            isClearable={true}
            options={zona && parseValues(zona)}
          />
        );
      case 'SELECT_OPTIONS_ROOMS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            isClearable={true}
            options={roomsData}
          />
        );
      case 'SPECIFIC_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={specificTypes && parseValues(specificTypes)}
          />
        );
      case 'EMPLOYEE':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formData?.[filter.id]?.value ?? ''}
              onChange={(e: SelectValue) =>
                handleChangeRange(filter.id, 'value', e)
              }
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={users && parseValues(users)}
            />
            <Form.Group id='formGridCheckbox' key={filter.id}>
              <Form.Check
                type='checkbox'
                label={'Agent asociat'}
                checked={
                  formData?.[filter.id]?.associated === 1 ||
                  formData?.[filter.id]?.associated === true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'associated', e.target.checked)
                }
              />
            </Form.Group>
          </div>
        );
      case 'CONTACT_DATA_SELECT':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formData?.[filter.id] ?? ''}
              onChange={(e: SelectValue) => handleChange(filter.id, e)}
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={contactData}
            />
          </div>
        );
      case 'INPUT':
      case 'OWNER_PHONE':
      case 'ID':
      case 'STREET_NUMBER':
      case 'KEYWORDS':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                value={formData?.[filter.id] || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(filter.id, e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE':
      case 'PRICE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={formData?.[filter.id]?.from || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'from', e.target.value)
                }
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={formData?.[filter.id]?.to || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'to', e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_SELECT':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formData?.[filter.id]?.from ?? ''}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'from', e)
                }
              />
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formData?.[filter.id]?.to ?? ''}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'to', e)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_DATE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input range-date date-column'>
              {showCalendar && (
                <div className='range-calendar' ref={myRef}>
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: formData?.[filter.id]?.from
                          ? new Date(formData?.[filter.id]?.from)
                          : undefined,
                        endDate: formData?.[filter.id]?.to
                          ? new Date(formData?.[filter.id]?.to)
                          : undefined,
                        key: 'selection',
                      },
                    ]}
                    locale={locale.enUS}
                    onChange={e => {
                      handleSelect(filter.id, e);
                    }}
                  />
                </div>
              )}
              <ReactDatePicker
                placeholderText={
                  formData?.[filter.id]?.from && formData?.[filter.id]?.to
                    ? `${moment(formData?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formData?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : 'De la/Pana la'
                }
                value={
                  formData?.[filter.id]?.from &&
                  formData?.[filter.id]?.to &&
                  !showCalendar
                    ? `${moment(formData?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formData?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : ''
                }
                selected={null}
                onCalendarOpen={() => setShowCalendar(true)}
                onChange={() => {}}
              />
            </div>
          </div>
        );
      case 'CHECKBOX':
      case 'VIRTUAL_TUR_CHECKBOX':
      case 'VIDEO_CHECKBOX':
      case 'FLOOR_EXCLUDED_NOT_SPECIFIC_CHECKBOX':
      case 'FLOOR_EXCLUDED_P_LAST_CHECKBOX':
      case 'HAS_CONTRACT_DATA_CHECKBOX':
      case 'EXCLUSIVENESS_CHECKBOX':
        return (
          <Form.Group id='formGridCheckbox' key={filter.id}>
            <Form.Check
              type='checkbox'
              label={filter.title}
              checked={
                formData?.[filter.id] === 1 || formData?.[filter.id] === true
                  ? formData?.[filter.id]
                  : false
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(filter.id, e.target.checked)
              }
            />
          </Form.Group>
        );
      default:
        return <p>Need to implement</p>;
    }
  };

  return (
    <div className='filter-container map-page-section__filters'>
      {isMobile && (
        <div className='map-page-section__filters--top'>
          <span>Filters</span>
          <div
            className='close-btn-modal'
            onClick={() => setOpenedFilters(false)}
          >
            <IconClose />
          </div>
        </div>
      )}
      {loadingFilters ? (
        <Spinner />
      ) : (
        <form>
          <div className={`filters-wrapper ${openedFilters ? 'opened' : ''}`}>
            <FormSelect
              value={
                activeCategory
                  ? parseValues(categories)?.find(
                      option => option.value === String(activeCategory),
                    )
                  : { value: 0, label: 'Toate' }
              }
              onChange={(e: SelectValue) => {
                setActiveCategory && setActiveCategory(Number(e.value));
              }}
              labelText={true}
              inlineLabel={'Categorie'}
              options={[
                { value: 0, label: 'Toate' },
                ...parseValues(categories),
              ]}
            />
            {filters &&
              filters.length > 0 &&
              [...filters]
                .sort(
                  (a: Filter, b: Filter) =>
                    Number(a.adGroup) - Number(b.adGroup),
                )
                .slice(0, openedFilters ? filters.length : 5)
                .map((filter: Filter) => renderContent(filter))}
          </div>
          {!isMobile && (
            <div className='bottom-filter-section'>
              <div className='form-btns-section'>
                <button
                  className='reset-btn more-btn'
                  onClick={e => {
                    e.preventDefault();
                    setOpenedFilters(!openedFilters);
                  }}
                >
                  <span>
                    {openedFilters ? 'Mai putine filtre' : 'Mai multe filtre'}
                  </span>
                </button>
              </div>
            </div>
          )}
          <div className='bottom-filter-btns'>
            <button
              className='button-blue'
              onClick={e => {
                submitFilters(e);
              }}
            >
              {loadingBtn ? (
                <span style={{ position: 'relative', minWidth: '30px' }}>
                  <Spinner className='btn-blue-spinner' />
                </span>
              ) : (
                <span>Filtreaza</span>
              )}
            </button>
            <button
              className='reset-btn '
              onClick={e => {
                resetFilters(e);
              }}
            >
              <span>Reseteaza</span>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default MapFilters;
