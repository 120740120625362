import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListHeaderPriceEvaluation from '../../components/FilterListHeaderPriceEvaluation';
import FilterListPriceEvaluation from '../../components/FilterListPriceEvaluation';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import Pagination from '../../components/Pagination';
import PriceEvaluationListItem from '../../components/PriceEvaluationListItem/PriceEvaluationListItem';
import PriceEvaluationRequestModal from '../../components/PriceEvaluationRequestModal';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getCategories } from '../../redux/categories/categoriesThunk';
import {
  getEvaluationFilters,
  getEvaluations,
} from '../../redux/evaluations/evaluationsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './PriceEvaluation.scss';

interface Props {}

const PriceEvaluation: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { evaluations, totalCount, loading } = useAppSelector(
    state => state.evaluations,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filtersForm, setFiltersForm] = useState({});
  const [prevState, setPrevState] = useState({
    currentPage: 0,
    itemsPerPage: 24,
    sort: '',
  });
  const [sort, setSort] = useState('');
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.EvaluationList),
  );
  const activeCategoryRef = useRef(activeCategory);
  const filtersFormRef = useRef(filtersForm);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getEvaluations({ offset: 0, limit: 20 }));
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / itemsPerPage);

      setPageCount(pageCount);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, itemsPerPage]);

  useEffect(() => {
    activeCategory === 0
      ? dispatch(getEvaluationFilters())
      : dispatch(getEvaluationFilters(activeCategory));

    if (filtersForm) {
      setFiltersForm({});
    } else {
      getEvaluationsList();
    }

    activeCategoryRef.current = activeCategory;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    let newState = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      sort: sort,
    };

    if (
      JSON.stringify(prevState.currentPage) !==
        JSON.stringify(newState.currentPage) ||
      JSON.stringify(prevState.sort) !== JSON.stringify(newState.sort) ||
      JSON.stringify(prevState.itemsPerPage) !==
        JSON.stringify(newState.itemsPerPage)
    ) {
      getEvaluationsList();
      setPrevState(newState);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, sort]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    } else {
      loadList();
    }

    filtersFormRef.current = filtersForm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };
  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };
  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const loadList = () => {
    if (currentPage > 0) {
      setCurrentPage(0);
    } else {
      getEvaluationsList();
    }
  };

  const getEvaluationsList = async () => {
    let filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      category: activeCategory,
      ...(sort ? { sort: sort } : {}),
      filters: filtersForm,
    };

    try {
      const result = await dispatch(getEvaluations(filters));
      unwrapResult(result);
    } catch (err: any) {}
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper price-evaluation-wrapper'>
        <Header
          title='Evaluare pret'
          subtitle=''
          btnEvent={() => navigate('/new-evaluation')}
          isbutton={true}
          btnText='Evaluare Noua'
        />
        {havePermission ? (
          <>
            {!isMobile && (
              <FilterListPriceEvaluation
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                filtersForm={filtersForm}
                setFiltersForm={setFiltersForm}
              />
            )}
            {/* <FilterCategory
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          filtersForm={filtersForm}
          setFiltersForm={setFiltersForm}
        /> */}
            <FilterListHeader
              pageCount={pageCount}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalCount={totalCount}
              count={evaluations?.length}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
              showActions={false}
              handlePageClick={handlePageClick}
            />
            <FilterListHeaderPriceEvaluation sort={sort} sortItems={setSort} />
            {loading ? (
              <div className='container'>
                <Spinner />
              </div>
            ) : (
              <>
                <div className='list-items-container'>
                  {evaluations.map(item => (
                    <PriceEvaluationListItem
                      key={item.id}
                      item={item}
                      getEvaluationsList={getEvaluationsList}
                    />
                  ))}
                </div>
                <Pagination
                  isSelectPage={false}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  handlePageClick={handlePageClick}
                />
              </>
            )}
            <FixedActions
              disabledActions={true}
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            {/* <ModalMobile
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          isAction={isAction}
          isSearch={isSearch}
          isFilter={isFilter}
          activeCategory={activeCategory}
          filtersForm={filtersForm}
          setActiveCategory={setActiveCategory}
          setFiltersForm={setFiltersForm}
        /> */}
            <PriceEvaluationRequestModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              activeCategory={activeCategory}
              filtersForm={filtersForm}
              setActiveCategory={setActiveCategory}
              setFiltersForm={setFiltersForm}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default PriceEvaluation;
