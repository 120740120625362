import * as locale from 'date-fns/locale';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import ReactDatePicker from 'react-datepicker';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import { parseValues } from '../../helpers/useHelper';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import Spinner from '../Spinner';
import './ReportsFilter.scss';

interface ReportsFilterForm {
  employees?: number[];
  dateFrom?: string;
  dateTo?: string;
}

interface Props {
  filtersForm: any;
  loadingBtn?: boolean;
  setLoadingBtn?: (e: boolean) => void;
  setFiltersForm: (e: any) => void;
  onSubmit?: () => void;
}

const ReportsFilter: React.FC<Props> = ({
  filtersForm,
  setFiltersForm,
  loadingBtn,
  onSubmit,
  setLoadingBtn,
}) => {
  const dispatch = useAppDispatch();
  const initialized = useRef(false);
  const [formData, setFormData] = useState<ReportsFilterForm>(filtersForm);
  const { users } = useAppSelector(state => state.users);
  const { error } = useAppSelector(state => state.reports);
  const [showCalendar, setShowCalendar] = useState(false);
  const myRef = useRef<any>();

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getLocalAgents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocalAgents = () => {
    dispatch(
      getUsers({
        roles: 'AGENCY',
        limit: '-1',
        offset: 1,
      }),
    );
  };

  useEffect(() => {
    setFormData(filtersForm);
  }, [filtersForm]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (JSON.stringify(formData) !== JSON.stringify(filtersForm)) {
      filter(formData);
      setLoadingBtn && setLoadingBtn(true);
    }
  };

  const filter = (formData: ReportsFilterForm) => {
    formData && setFiltersForm(formData);
  };

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current?.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const resetForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFiltersForm({
      dateFrom: moment(new Date()).format('YYYY-MM-DD'),
      dateTo: moment(new Date()).format('YYYY-MM-DD'),
    });
  };

  return (
    <div className='filter-form-container contacts-filter-container'>
      <div className='filter-container'>
        <form>
          <div className='form-line-section'>
            <div className='form-item'>
              <MultiselectCheckboxes
                onChange={(e: number[]) => {
                  setFormData({
                    ...formData,
                    employees: e,
                  });
                }}
                labelText={true}
                inlineLabel={'Agentii'}
                value={
                  formData?.employees
                    ? formData.employees.map(employee => String(employee))
                    : []
                }
                options={parseValues(users) ?? []}
              />
            </div>
            <div className='form-group'>
              <label>Data</label>
              <div className='flex-input range-date date-column'>
                {showCalendar && (
                  <div className='range-calendar' ref={myRef}>
                    <DateRangePicker
                      ranges={[
                        {
                          startDate: formData?.dateFrom
                            ? new Date(formData?.dateFrom)
                            : undefined,
                          endDate: formData?.dateTo
                            ? new Date(formData?.dateTo)
                            : undefined,
                          key: 'selection',
                        },
                      ]}
                      locale={locale.enUS}
                      onChange={e => {
                        setFormData({
                          ...formData,
                          dateFrom: moment(e.selection?.startDate).format(
                            'YYYY-MM-DD',
                          ),
                          dateTo: moment(e.selection?.endDate).format(
                            'YYYY-MM-DD',
                          ),
                        });
                      }}
                    />
                  </div>
                )}
                <ReactDatePicker
                  placeholderText={
                    formData?.dateTo && formData?.dateTo
                      ? `${moment(formData?.dateTo).format('YYYY-MM-DD')} - ${moment(formData?.dateTo).format('YYYY-MM-DD')}`
                      : 'De la/Pana la'
                  }
                  value={
                    formData?.dateTo && formData?.dateTo && !showCalendar
                      ? `${moment(formData?.dateTo).format('YYYY-MM-DD')} - ${moment(formData?.dateTo).format('YYYY-MM-DD')}`
                      : ''
                  }
                  className={error?.dateFrom ?? error?.dateTo ? 'error' : ''}
                  selected={null}
                  onCalendarOpen={() => setShowCalendar(true)}
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
          <div className='bottom-filter-section'>
            <div className='form-btns-section'>
              <button className='reset-btn' onClick={e => resetForm(e)}>
                Reseteaza{' '}
              </button>
              <button
                className='filter-btn btn-blue'
                onClick={e => handleSubmit(e)}
              >
                {loadingBtn && <Spinner className='btn-blue-spinner' />}
                Filtreaza
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportsFilter;
